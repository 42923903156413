import React, { FC, Fragment,ReactNode,useEffect,useState,useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import { useWishlist } from "WishlistContext/WishlistContext";
import { useHistory } from "react-router-dom";
import { useAuth } from "hooks/useAuth"; // Adjust path if needed
export interface LikeSaveBtnsProps {
  className?: string;
  defaultValue?:boolean;
  onChange?:() => void;
  listingId: string; // ✅ Accepts the listing ID
}
const LikeSaveBtns: FC<LikeSaveBtnsProps> = ({
  className = "",
  onChange,
  listingId
}) => {
     const { user } = useAuth();
   const { wishlist, toggleWishlist } = useWishlist();
      const history = useHistory();
      const userId = user?.user_id; // Fetch user ID from localStorage
      const isWishlisted = wishlist.includes(listingId);
    
      const handleWishlistClick = (e: React.MouseEvent) => {
        e.preventDefault(); // Prevent navigating to listing when clicking the heart
        toggleWishlist(listingId); // ✅ Calls add/remove wishlist function
      };

  const handleChange = async (event:any) => onChange && onChange();
 
  const LikeSaveBtns = () => {
    return (
      <div className="flow-root">
        <div className="flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5">
          <span onClick={handleWishlistClick} className={`d-flex items-center py-1.5 px-3 flex rounded-lg cursor-pointer ${
            isWishlisted ? "text-red-500" : "hover:bg-neutral-100 dark:hover:bg-neutral-800"
          }`}>
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill={isWishlisted ? "red" : "none"} viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"></path></svg>
            <span className="hidden sm:block ml-2.5 uppercase">Bucket List</span>
          </span>
          
        </div>
      </div>
    );
  };
  return (
    <>
      {LikeSaveBtns()}
     
    </>
  );
};
export default LikeSaveBtns;





