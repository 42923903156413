import ButtonPrimary from "shared/Button/ButtonPrimary";
import React from "react";
import { Helmet } from "react-helmet";
import NcImage from "shared/NcImage/NcImage";
import I404Png from "images/404.png";

const Page404: React.FC = () => (
  <div className="nc-Page404">
    <Helmet>
      <title>404 || Booking React Template</title>
    </Helmet>
    <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
      {/* HEADER */}
      <header className="text-center space-y-2">
        {/* <NcImage src={I404Png} />
        <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
          THE PAGE YOU WERE LOOKING FOR DOESN'T EXIST.{" "}
        </span> */}

        <div className="flex-404-page">
          <div className="flex-100">
            <h1>404</h1>
            <p>Oops! Your Tiny House Wandered Off... <br></br>
            Looks like this page has gone off-grid! But don't worry-you can always find your next tiny house adventure.</p>
            <a href="/">
            <ButtonPrimary href="/">Find Your Next Adventure</ButtonPrimary>
            </a>
            </div>
          <div className="pt-8">
          <NcImage src="https://pictures.tinyaway.com/filters:format(svg)/icons/TA_UIUX_Error.svg" />
        </div>
        </div>
        
      </header>
    </div>
  </div>
);

export default Page404;
