import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";

const VerifyEmail = () => {
  const [message, setMessage] = useState("");
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    if (token) {
      axios
        .get(`${process.env.REACT_APP_BASE_URL}verify-email?token=${token}`)
        .then((response) => {
          if (response.data.status) {
            setMessage("Email verified successfully! You can now log in.");
            history.push("/login"); 
          } else {
            setMessage("Invalid or expired verification link.");
          }
        })
        .catch(() => {
          setMessage("Something went wrong. Please try again.");
        });
    }
  }, [location]);

  return (
    <div className="container">
      <h2>Email Verification</h2>
      <p>{message}</p>
      {message.includes("successfully") && <button onClick={() => history.push("/login")}>Go to Login</button>}
    </div>
  );
};

export default VerifyEmail;
