import { useContext } from "react";
import { AuthContext } from "AuthContext/AuthContext"; // Adjust path if needed

export const useAuth = () => {
  const auth = useContext(AuthContext);
  if (!auth) {
    throw new Error("useAuth must be used within an AuthProvider"); // Ensures it's always used inside the provider
  }
  return auth;
};
