import rightImg from "images/about-hero-right.png";
import videoplayback from "images/videoplayback.mp4";
import React, { FC, useState, useEffect, ComponentType } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import WishListCard from "components/WishListCard/WishListCard";
import axios from 'axios';
import CommonLayout from "../AccountPage/CommonLayout";
import { useAuth } from "hooks/useAuth"; // Adjust path if needed
export interface PageWishlistsProps {
  className?: string;
}
const PageWishlists: FC<PageWishlistsProps> = ({
  className = ""
}) => {
  const [wishListData, setWishListData] = useState([]);
  const [isdataLoaded, setIsdataLoaded] = useState(false);
  const { user } = useAuth();
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_BASE_URL}getAccountWishlist`,{
      params: { user_id: user?.user_id }
    })
      .then(res => {
        console.log(res.data);
        setWishListData(res.data.listings);
        setIsdataLoaded(true);
      })
  }, [isdataLoaded]);
  const renderWishlists = () => {
    return (
      <div className="right-user-details">
        <div className="flex justify-between">
          <h4>Wishlists</h4>
          {/* <div className="search-wishlist">
            <span><i className="la la-search"></i></span>
            <input type="" placeholder="Search location" />
          </div> */}
        </div>
        <div className="line-mid mt-5 mb-5"></div>
        <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 G1">
       {wishListData.length > 0 && 
       
       wishListData.map((stay) => (
        <WishListCard key={stay['id']} data={stay} />
      ))
       }

        </div>
      </div>
    );
  };
  return (
    <div className="bgcolor-light pb-14 pt-10">
      <Helmet title="Tiny Away | Wishlist"></Helmet>
      <div className="container">
        <div className="row-profile  mt-5 mb-5">
          <CommonLayout />
          {renderWishlists()}
        </div>
      </div>
    </div>
  );
};

export default PageWishlists;
