import React, { createContext, useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { useAuth } from "hooks/useAuth"; // Adjust path if needed

const WishlistContext = createContext<any>(null);

export const WishlistProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuth();
  const history = useHistory();
  const userId = user?.user_id; // Fetch logged-in user ID

  const [wishlist, setWishlist] = useState<string[]>(() => {
    try {
      const storedWishlist = localStorage.getItem("wishlist");
      return storedWishlist ? JSON.parse(storedWishlist) : []; // ✅ Handle empty case
    } catch (error) {
      console.error("Error parsing wishlist from localStorage:", error);
      return []; // ✅ Return empty array on error
    }
  });
  // ✅ Fetch wishlist from API if not in localStorage
  useEffect(() => {
    if (!userId) return; // Don't fetch if user is not logged in

    const fetchWishlist = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}getWishlist`, {
          params: { user_id: userId },
        });

        if (response.data.status) {
          setWishlist(response.data.wishlist);
          localStorage.setItem("wishlist", JSON.stringify(response.data.wishlist));
        }
      } catch (error) {
        console.error("Error fetching wishlist:", error);
      }
    };

    fetchWishlist();
  }, [userId]);

  // ✅ Toggle wishlist and update in the database
  const toggleWishlist = async (listingId: string) => {
    if (!userId) {
      history.push("/login"); // Redirect if not logged in
      return;
    }

    let updatedWishlist;
    if (wishlist.includes(listingId)) {
      updatedWishlist = wishlist.filter((id) => id !== listingId);

      // ❌ Remove from wishlist in database
      await axios.post(`${process.env.REACT_APP_BASE_URL}removeFromWishlist`, {
        user_id: userId,
        listing_id: listingId,
      });
    } else {
      updatedWishlist = [...wishlist, listingId];

      // ✅ Add to wishlist in database
      await axios.post(`${process.env.REACT_APP_BASE_URL}addToWishlist`, {
        user_id: userId,
        listing_id: listingId,
      });
    }

    setWishlist(updatedWishlist);
    localStorage.setItem("wishlist", JSON.stringify(updatedWishlist));
  };

  return (
    <WishlistContext.Provider value={{ wishlist, toggleWishlist }}>
      {children}
    </WishlistContext.Provider>
  );
};

export const useWishlist = () => useContext(WishlistContext);
