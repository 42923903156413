import React, { useEffect } from "react";

const SuccessMessage = ({ message, onClose }: any) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000); // Hide after 3 seconds

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div className="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white shadow-lg px-4 py-2 rounded-lg flex items-center gap-2 border border-gray-300">
      <span className="text-green-500">✅</span>
      <span className="text-gray-700">{message}</span>
    </div>
  );
};

export default SuccessMessage;
