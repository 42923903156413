import Label from "components/Label/Label";
import React, { FC, Fragment, useState, useEffect, useRef } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import ButtonCircle from "shared/Button/ButtonCircle";
import axios from "axios";
import { useAuth } from "hooks/useAuth"; // Adjust path if needed
export interface PageGiftCardsRedeemProps {
  className?: string;
}
const PageGiftCardsRedeem: FC<PageGiftCardsRedeemProps> = ({
  className = "",
}) => {
  const { user } = useAuth();
  const [voucherCode, seVoucherCode] = useState("");
  const [error, setError] = useState("");
  const [resultMessage, setResultMessage] = useState("");
  const [activeTab, setActiveTab] = useState<"available" | "notAvailable">("available");
  const [availableCoupons, setAvailableCoupons] = useState<any[]>([]);
  const [notAvailableCoupons, setNotAvailableCoupons] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const fetchGiftCards = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}getUserGiftCards`, {
        params: { user_id: user?.user_id }
      });

      if (response.data.success) {
        setAvailableCoupons(response.data.available_coupons || []);
        setNotAvailableCoupons(response.data.not_available_coupons || []);
      } else {
        setAvailableCoupons([]);
        setNotAvailableCoupons([]);
      }
    } catch (error) {
      console.error("Error fetching GiftCard codes:", error);
    } finally {
      setLoading(false);
    }
  };
    useEffect(() => {
      fetchGiftCards();
    }, []);
  
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    seVoucherCode(e.target.value);
    setError(""); // Clear error on change
  };

  // ✅ Apply GiftCard code & refresh slider
  const handleApplyCode = async () => {
    if (!voucherCode.trim()) {
      setError("Please enter a gift card.");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}redeemGiftCard`,
        { voucherCode, user_id: user?.user_id }
      );

      if (response.data.success) {
        setResultMessage("GiftCard code applied successfully!");
        seVoucherCode(""); // Clear input
        fetchGiftCards(); // ✅ Refresh slider
      } else {
        setResultMessage(response.data.message || "Invalid GiftCard code.");
      }
    } catch (error) {
      setResultMessage("Gift card code is invalid.");
    }
  };

  const renderGiftCard = () => {
    return (
      <div className="right-user-details">
        <div className="flex justify-between">
          <h4>Gift Card</h4>
          {/* <div className="search-wishlist">
            <span><i className="la la-search"></i></span>
            <input type="" placeholder="Search location" />
          </div> */}
        </div>
        {/* <div className="list-tab-account">
    <a href="" className="active">All Purchased</a>
    <a href="">Completed</a> 
    <a href="">IN PROGRESS</a>
    </div> */}
        <div className="mt-7 relative max-w-sm">
              <Input
                  type="text"
                  placeholder="Enter gift code and redeem"
                  value={voucherCode}
                  onChange={handleInputChange}
                />
                <ButtonCircle
                  onClick={handleApplyCode}
                  className="absolute transform top-1/2 -translate-y-1/2 right-1"
                >
                  <i className="las la-arrow-right text-xl"></i>
                </ButtonCircle>
                
              </div>
        {error && <p className="text-red-500 mt-2">{error}</p>}
        {resultMessage && <p className="text-green-500 mt-2">{resultMessage}</p>}

        {/* ✅ Tabs for Available & Not Available */}
        <div className="list-tab-account mt-5">
          <a
            href="javascript:void(0);"
            className={`tab ${activeTab === "available" ? "active" : ""}`}
            onClick={() => setActiveTab("available")}
          >
            Available
          </a>
          <a
            href="javascript:void(0);"
            className={`tab border-l ${activeTab === "notAvailable" ? "active" : ""}`}
            onClick={() => setActiveTab("notAvailable")}
          >
            Not Available
          </a>
        </div>
        <div className="line-mid mt-5 mb-5"></div>
        {(activeTab === "available" ? availableCoupons : notAvailableCoupons).map((item, index) => (
          <>
          <div className="flex">
          <div className="w-70">
            <div className="content w-1/2">
              <h4>{item.heading}</h4>
              <p>{item.gift_message}</p>
              <p className="color-gry">Value: {item.base_currency} {item.purchased_value} | Qty: 1</p> 
              <p className="color-gry">Remaining balance : {item.base_currency} {item.remaining_balance}</p>
              <p className="color-gry">Purchased Date : {item.purchased_at}</p>
              <h4>Sent To</h4>
              <p>{item.recipient_email}</p>
              <p className="color-gry">Send by : your self</p>
            </div>
          </div>
          <div className="w-30">
            <div className="right-box-add-on gap-3">
              <a href="" className="btn btn-box-theme">{item.voucher_schedule_status}</a>
              <img src={item.download_path} />
              {item.voucherpdf_link!='' && 
              <a target="_blank" href={item.voucherpdf_link} className="confirmed color-light-blu">Download Gift Link</a>
              }
            </div>
          </div>
        </div>
        <div className="line-mid mt-5 mb-5"></div>
          </>
        ))}
      </div>
    );
  };

  return (
    <div className="bgcolor-light pb-14 pt-10">
      <Helmet title={'Tiny Away Available Gift Card'}></Helmet>
      <div className="container">
        <div className="row-profile  mt-5 mb-5">
          <CommonLayout />
          {renderGiftCard()}
        </div>
      </div>
    </div>
  );
};
export default PageGiftCardsRedeem;
