import React, { Fragment,useState } from "react";
import { FC } from "react";
import { useEffect } from "react";
import { Dialog, Popover, Transition } from "@headlessui/react";
import NcInputNumber from "components/NcInputNumber/NcInputNumber";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import { Range } from "rc-slider";
import { useRef } from "react";
import convertNumbThousand from "utils/convertNumbThousand";
import { valueContainerCSS } from "react-select/dist/declarations/src/components/containers";
export interface TabFiltersProps {
  totalPages?: number;
  onClick?: (value: string) => void;
  onFiltersChange: (filters: FiltersState) => void; // ✅ New prop
}

interface FiltersState {
  experienceType: string[];
  amenities: string[];
  rooms: { beds: number; bedrooms: number; bathrooms: number };
}
const typeOfPaces = [
  {
    name: "Family friendly",
    description: "",
    value:"familty_friendly"
  },
  {
    name: "Pet friendly",
    description: "",
    value:"pet_friendly"
  },
  {
    name: "Digital detox",
    description:"",
    value:"digital_detox"
  }
];
const AmenitiesFacilitied = [
  {
    name: "Hot tub bliss",
    description: "",
    value:"Hot_tub_bliss"
  },
  {
    name: "BBQ lovers",
    description: "",
    value:"bbq_lovers"
  },
  {
    name: "Fire Pit Nights",
    description:"",
    value:"fire_pit_lovers"
  }
];
const TabFilters: React.FC<TabFiltersProps> = ({ totalPages, onClick, onFiltersChange }) =>{
  const [filters, setFilters] = useState<FiltersState>({
    experienceType: [],
    amenities: [],
    rooms: { beds: 0, bedrooms: 0, bathrooms: 0 },
  });
  const [isOpenMoreFilter, setisOpenMoreFilter]               = useState(false);
  const [tempFilters, setTempFilters] = useState<FiltersState>({ ...filters });

  const updateFilters = (newFilters: Partial<FiltersState>) => {
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, ...newFilters };
      onFiltersChange(updatedFilters); // ✅ Send to parent
      return updatedFilters;
    });
  };
  useEffect(() => {
    //onFiltersChange(filters);
  }, [filters]); // Runs only when filters change
  const handleRoomChange = (roomType: keyof FiltersState["rooms"], value: number) => {
    setTempFilters((prev) => ({
      ...prev,
      rooms: { ...prev.rooms, [roomType]: value },
    }));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, filterType: "experienceType" | "amenities") => {
    const { checked, value } = event.target;
    setTempFilters((prev) => ({
      ...prev,
      [filterType]: checked ? [...prev[filterType], value] : prev[filterType].filter((item) => item !== value),
    }));
  };
  console.log("besss"+filters.amenities);
  const closeModalMoreFilter = () => setisOpenMoreFilter(false);
  // const openModalMoreFilter = () => setisOpenMoreFilter(true);

  const openModalMoreFilter = () => {
    setTempFilters({ ...filters }); // Reset tempFilters to last applied filters
    setisOpenMoreFilter(true);
  };

  // const closeModalMoreFilterMobile = () => setisOpenMoreFilterMobile(false);
  // const openModalMoreFilterMobile = () => setisOpenMoreFilterMobile(true);

  const applyFilters = () => {
    setFilters(tempFilters); // Save applied filters
    onFiltersChange(tempFilters); // Send to parent
    closeModalMoreFilter();
  };
  const renderXClear = () => {
    return (
      <span className="w-4 h-4 rounded-full bg-primary-500 text-white flex items-center justify-center ml-3 cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-3 w-3"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fillRule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </span>
    );
  };

  const renderTabMoreFilter = () => {
    return (
      <div>
        <div
          className={`flex items-center justify-center px-4 py-2 text-sm`}
          onClick={openModalMoreFilter}
        >
          <ButtonPrimary  sizeClass="px-4 py-2 sm:px-5 flex  items-center" >
            <i className="text-lg las la la-filter">
            <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" role="presentation" focusable="false" >
              <path d="M5 8c1.306 0 2.418.835 2.83 2H14v2H7.829A3.001 3.001 0 1 1 5 8zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm6-8a3 3 0 1 1-2.829 4H2V4h6.17A3.001 3.001 0 0 1 11 2zm0 2a1 1 0 1 0 0 2 1 1 0 0 0 0-2z">
              </path></svg>
            </i>
            <span>Filters</span>
          </ButtonPrimary>
        </div>

        <Transition appear show={isOpenMoreFilter} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-50 overflow-y-auto"
            onClose={closeModalMoreFilter}
          >
            <div className="min-h-screen text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-40 dark:bg-opacity-60" />
              </Transition.Child>

              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="inline-block h-screen align-middle"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                className="inline-block py-8 h-screen w-full"
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <div className="inline-flex flex-col w-full max-w-4xl-4 text-left align-middle transition-all transform overflow-hidden rounded-2xl bg-white dark:bg-neutral-900 dark:border dark:border-neutral-700 dark:text-neutral-100 shadow-xl h-full">
                  {/* <div className="relative flex-shrink-0 px-6 py-4 border-b border-neutral-200 dark:border-neutral-800 text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      More filters
                    </Dialog.Title>
                    <span className="absolute left-3 top-3">
                      <ButtonClose onClick={closeModalMoreFilter} />
                    </span>
                  </div> */}
                  
                  <div className="flex-grow overflow-y-auto  model-price-filters">
                    <div className="px-10 divide-y divide-neutral-200 dark:divide-neutral-800 ">
                    {/* <div className="py-7">
                      <div className="overflow-hidden dark:bg-neutral-900  dark:border-neutral-700">
                        <div className="relative flex flex-col px-5 py-6 space-y-8">
                          <div className="space-y-5">
                            <h3 className="text-xl font-medium">Price per day</h3>
                            <Range
                              className="text-red-400"
                              min={0}
                              max={2000}
                              defaultValue={[0, 1000]}
                              allowCross={false}
                              onChange={setRangePrices}
                            />
                          </div>

                          <div className="flex  space-x-5">
                            <div>
                              <label
                                htmlFor="minPrice"
                                className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                              >
                                Min price
                              </label>
                              <div className="mt-1 relative rounded-md">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                  <span className="text-neutral-500 sm:text-sm">
                                    $
                                  </span>
                                </div>
                                <input
                                  type="text"
                                  name="minPrice"
                                  disabled
                                  id="minPrice"
                                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                  value={rangePrices[0]}
                                />
                              </div>
                            </div>
                            <div>
                              <label
                                htmlFor="maxPrice"
                                className="block text-sm font-medium text-neutral-700 dark:text-neutral-300"
                              >
                                Max price
                              </label>
                              <div className="mt-1 relative rounded-md">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                  <span className="text-neutral-500 sm:text-sm">
                                    $
                                  </span>
                                </div>
                                <input
                                  type="text"
                                  disabled
                                  name="maxPrice"
                                  id="maxPrice"
                                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-3 sm:text-sm border-neutral-200 rounded-full text-neutral-900"
                                  value={rangePrices[1]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      
                      </div>
                    </div> */}
                    <div className="py-7">
                    <h3 className="text-xl font-medium">Experience Type</h3>
                    <div className="overflow-hidden dark:bg-neutral-900  dark:border-neutral-700">
                          <div className="relative flex flex-row px-5 py-6 space-y-5">
                            {typeOfPaces.map((item) => (
                              <div key={item.name} className="">
                                <Checkbox
                                  name={item.name}
                                  label={item.name}
                                  value={item.value}
                                  subLabel={item.description}
                                  defaultChecked={tempFilters.experienceType.includes(item.value)}
                                  onChange={(event) => handleCheckboxChange(event, "experienceType")} 
                                />
                              </div>
                            ))}
                          </div>
                          
                        </div>
                    </div>             
                    <div className="py-7">
                    <h3 className="text-xl font-medium">Amenities</h3>
                    <div className="overflow-hidden dark:bg-neutral-900  dark:border-neutral-700">
                          <div className="relative flex flex-row px-5 py-6 space-y-5">
                            {AmenitiesFacilitied.map((item) => (
                              <div key={item.name} className="">
                                <Checkbox
                                  name={item.name}
                                  label={item.name}
                                  value={item.value}
                                  subLabel={item.description}
                                  defaultChecked={tempFilters.amenities.includes(item.value)} // ✅ Show checked state
                                  onChange={(event) => handleCheckboxChange(event, "amenities")} // ✅ Update state
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                    </div>  
                    <div className="py-7">
                        <h3 className="text-xl font-medium">Rooms and beds</h3>         
                        <div className="overflow-hidden dark:bg-neutral-900  dark:border-neutral-700">
                          <div className="flex flex-col gap-4 relative w-30 mt-2">
                            <NcInputNumber className="w-2/100 justify-start" label="Beds" max={10}
                            defaultValue={tempFilters.rooms.beds}
                            onChange={(value) => handleRoomChange("beds", value)}
                            />
                            <NcInputNumber className="w-2/100 justify-start" label="Bedrooms" max={10}
                            defaultValue={tempFilters.rooms.bedrooms} 
                            onChange={(value) => handleRoomChange("bedrooms", value)}
                            />
                          </div>
                        </div>
                    </div>
                    </div>
                  </div>

                  <div className="p-4 flex-shrink-0  flex items-center justify-end">
                    <ButtonThird
                      onClick={applyFilters}
                      sizeClass="px-4 py-2 sm:px-5"
                    >
                      Apply
                    </ButtonThird>
                    {/* <a
                      // onClick={(e) => Setsearchquery(e.currentTarget.href)}
                      onClick={(e) => e.preventDefault()}
                      href ={searchquery}
                      className="px-4 py-2 sm:px-5"
                    >
                     Show {totalPages} Stays
                    </a> */}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition>
      </div>
    );
  };

  return (
    <div className="flex lg:space-x-4">
      <div className="hidden lg:flex space-x-4">
        {/* {renderTabsTypeOfPlace()} */}
        {/* {renderTabsPriceRage()} */}
        {/* {renderTabsRoomAndBeds()} */}
        {renderTabMoreFilter()}
      </div>
    </div>
  );
};

export default TabFilters;
