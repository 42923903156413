import React, { FC, Fragment, useState, useEffect, useRef } from "react";
import { Route, Redirect, useHistory, Link, useParams } from 'react-router-dom';
import Label from "components/Label/Label";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import ProgressBar from 'react-customizable-progressbar';
import { Helmet } from "react-helmet";
import axios from 'axios';


export interface OurRewordsPageProps {
  className?: string;
  DefaultUserID?: string;
  autoFocus?: boolean;
}

const OurRewords: FC<OurRewordsPageProps> = ({
  className = "",
  DefaultUserID = localStorage.getItem("user_id") ? localStorage.getItem("user_id") : "",
  autoFocus = false
}) => {
  const history                                                   =   useHistory();
  const [user_id, setUserId]                                      =   useState(DefaultUserID);
  const [totalRewardPoints, setTotalRewardPoints]                 =   useState("");
  const [tierFirst, settierFirst]                                 =   useState(0);
  useEffect(() => {
    setUserId(localStorage.getItem("user_id"));
    if (DefaultUserID == "") {
      history.push("404");
    }
  }, [DefaultUserID]);

  useEffect(() => {
    console.log(process.env.REACT_APP_BASE_URL + 'getUserRewards?user_id=' + DefaultUserID);
    axios.get(process.env.REACT_APP_BASE_URL + 'getUserRewards?user_id=' + DefaultUserID)
      .then(res => {
        setTotalRewardPoints(res.data.rewards.points);
        settierFirst(res.data.rewards.tier);
      })
    })

  const renderRewards = () =>{
    return(
      <>
      <div className="box-tranform box-shadow-card">
        <div className="card-Rewards Explorer">
          <div className="ProgressBar">
            <div className="flex-ProgressBar">
              <div>
                <ProgressBar
                radius={100}
                progress={tierFirst}
                rotate={-180}
                strokeWidth={10}
                strokeColor="#df485f"
                strokeLinecap="round"
                trackStrokeWidth={10}
                trackStrokeColor="#e6e6e6"
                trackStrokeLinecap="square"
                pointerRadius={-210}
                initialAnimation={true}
                transition="1.5s ease 0.5s"
                trackTransition="0s ease"
                counterClockwise
                >
                <div className="indicator">
                {/* <p><b>KrisFlyer elite Silver</b></p> */}
                <p className="color-light"></p>
                <div className="color-w-prograsiv">{totalRewardPoints}</div>
                <p className="color-light">points earned</p>
                </div>
                </ProgressBar>
              </div>
            </div>
            <div className="box-xx-points"><span></span><div>1350 more Tiny Away points to achieve Explorer status</div></div>
          </div>
        </div>
      </div>
      </>
    );
  }
  const renderMileStoneProgressBar = () =>{
    return(
          <div className="vefs-milestone-wrapper">
            <div className="milestone-container">
              <div className="chart-container">
                <div className="line-container">
                  <div className="line"></div>
                  <div className="line left" style={{width: "5%"}}></div>
                </div>
                <div className="dot-container">
                  <div className="milestones milestone__10">
                    <div className="dot completed colored"><i className=" las la-gift text-2xl "></i></div>
                  </div>
                  <div className="milestones milestone__25">
                    <div className="dot completed colored"><i className=" las la-gift text-2xl "></i></div>
                  </div>
                  <div className="milestones milestone__50">
                    <div className="dot completed colored"><i className=" las la-gift text-2xl "></i></div>
                  </div>
                  <div className="milestones milestone__80">
                    <div className="dot"><i className=" las la-gift text-2xl "></i></div>
                  </div>
                </div>
              </div>
              <div className="label-container">
                <div className="milestones milestone__10">
                  <div className="label colored">500 Tiny Away Points <br/> $20 gift card</div>
                </div>
                <div className="milestones milestone__25">
                  <div className="label colored">1000 Tiny Away Points<br/> $50 gift card</div>
                </div>
                <div className="milestones milestone__50">
                  <div className="label colored">1500 Tiny Away Points<br/>$100 gift card</div>
                </div>
                <div className="milestones milestone__80">
                  <div className="label">2000 Tiny Away Points<br/>$150 gift card</div>
                </div>
              </div>
            </div>
          </div>
    );
  }
  const AccountPageMidSection = () => {
    return (
      <div className="right-user-details-card">
        <div className="card">
          <div>
      <h4>How to level up</h4>
       <p>Get rewarded for exploring and enjoy exclusive deals</p>
       </div>
       <div className="reword-card-sr">
        <div className="flex justify-between">
          <div className="card card-border-1">
            <span className="start"><i className="las la-star"></i></span>
            <div className="text-reword-bold">600</div>
            <h5>Your Balance Points</h5>
            <p>Earn more points, redeem exciting gifts and enjoy thh experience</p>
            <div>
              <a href="">Reward points history </a>
              <a href=""> How to earn points?</a>
              <a href=""> How does the loyalty program work?</a>
            </div>
          </div>
          <div>8</div>
          </div>
       </div>
       </div>
    </div>
    )
  }

      return(
        <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
          <div className="bgcolor-light pb-14 pt-10">
      <div className="container">

        <div className="row-profile  mt-5 mb-5">
         
        <CommonLayout/>
        {AccountPageMidSection ()}
         
        </div>
      </div>
    </div>
        </div>
      )
}
export default OurRewords;