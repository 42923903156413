import React, { FC  } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link, useHistory } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import PetFriendly from "components/PetFriendly/petFriendly";
import PriceBadge from "components/PriceBadge/priceBadge";
import Badge from "shared/Badge/Badge";
import { useWishlist } from "WishlistContext/WishlistContext";
import { useAuth } from "hooks/useAuth"; // Adjust path if needed


export interface StayCardProps {
  className?: string;
  ratioClass?: string;
  data?: StayDataType;
  size?: "default" | "small";
  descriptiondiv?:boolean
}

const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const StayCard: FC<StayCardProps> = ({
  size = "default",
  className = "",
  data = DEMO_DATA,
  ratioClass = "aspect-w-3 aspect-h-3",
  descriptiondiv=true
}) => {
  const {
    galleryImgs,
    listingCategory,
    address,
    title,
    bedrooms,
    beds,
    maxGuests,
    bathrooms,
    href,
    like,
    saleOff,
    pets_frendly,
    isAds,
    price,
    reviewStart,
    reviewCount,
    id,
    currency_code,
    publicDescription_summary
  } = data;
  const { user } = useAuth();
  const { wishlist, toggleWishlist } = useWishlist();
  const history = useHistory();
  const userId = user?.user_id; // Fetch user ID from localStorage
  const isWishlisted = wishlist.includes(data.id);

  const handleWishlistClick = (e: React.MouseEvent) => {
    e.preventDefault(); // Prevent navigating to listing when clicking the heart
    toggleWishlist(data.id); // ✅ Calls add/remove wishlist function
  };

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`stay-v-${id}`}
          ratioClass={ratioClass}
          galleryImgs={galleryImgs}
        />
        <div className="absolute flex justify-between justify-content-between left-2 pr-3 top-2 w-100 w-full">
          <div className="flex gap-2">
        {saleOff && <SaleOffBadge desc={saleOff} />}
        {pets_frendly && <PetFriendly desc={pets_frendly}  />}
        </div>
        <div>
        <button onClick={handleWishlistClick} className="focus:outline-none">
        <BtnLikeIcon isLiked={isWishlisted}/>
        </button>
        </div>
        </div>
        {/*{pets_frendly && <PriceBadge desc={pets_frendly} className="absolute left-1 bottom-5" />}*/}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-0 space-y-0" : "p-3 space-y-2"}>
        <div className="flex p-4">
        <div className="space-y-0 w-5/6">

          <div className="flex items-center space-x-2">
            {isAds && <Badge name="ADS" color="green" />}
            <h2
              className={` font-medium capitalize ${
                size === "default" ? "text-lg" : "text-base"
              }`}
            >
              <span className="line-clamp-1">{title}</span>
            </h2>
          </div>
          {/* <span className="text-sm text-neutral-500 dark:text-neutral-400">
           
           Tiny House . {bedrooms} bedroom
          </span> */}
          <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
           <span className="">{address}</span>
          </div>
          {!!reviewStart && (
              <StartRating reviewCount={reviewCount} point={reviewStart} isHost={false} />
            )}
        </div>
       
        <div className="flex justify-between items-top">
          <span className="text-lg font-semibold text-right">
            {currency_code}{price}
            {` `}
            {size === "default" && (
              <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                /night
              </span>
            )}
          </span>
        
        </div>
        </div>
        <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
        {descriptiondiv==true && 
        <div className="flex  p-4">
        <div className="min-height-listing">
        {publicDescription_summary}
        </div>
      </div>
        }
        
        <div className="hidden sm:grid grid-cols-4 gap-0 p-4 hover-color-card-btn">
        <div className="space-y-0">
            <div className="flex items-center space-x-1">
              <i className="las la-user-circle text-lg"></i>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 uppercase">
                {maxGuests} guest
              </span>
            </div>
          </div>
          
         <div className="space-y-0">
            <div className="flex items-center space-x-1">
              <i className="las la-bed text-lg"></i>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 uppercase">
              {beds} bed
              </span>
            </div>
          </div>
          <div className="space-y-0">
            <div className="flex items-center space-x-1">
              <i className="las la-bath text-lg"></i>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 uppercase">
              {bathrooms} bath
              </span>
            </div>
          </div>
          <div className="space-y-0">
            <div className="flex items-center space-x-1">
              <i className="las la-door-open text-lg"></i>
              <span className="text-sm text-neutral-500 dark:text-neutral-400 uppercase">
              {bedrooms} bedroom
              </span>
            </div>
          </div>
        </div> 
      </div>
    );
  };

  return (
    <div
      className={`hover-bg-color nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      <Link to={href}>
        {renderSliderGallery()}
        {renderContent()}
      </Link>
    </div>
  );
};

export default StayCard;
