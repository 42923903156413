import { StarIcon as SolidStarIcon } from "@heroicons/react/solid";
import { StarIcon as OutlineStarIcon } from "@heroicons/react/outline";
import { FC } from "react";

export interface StartRatingProps {
  className?: string;
  point?: number;
  reviewCount?: number;
  isHost?: boolean;
}

const StartRating: FC<StartRatingProps> = ({
  className = "",
  point = 4.5,
  reviewCount = 112,
  isHost = false,
}) => {
  const fullStars = Math.floor(point); // Count of full stars
  const decimalPart = point - fullStars;
  const hasHalfStar = decimalPart >= 0.3 && decimalPart <= 0.7;
  const shouldRoundUp = decimalPart > 0.7; // If decimal > 0.7, round to next full star

  const totalFullStars = shouldRoundUp ? fullStars + 1 : fullStars;
  const totalHalfStars = shouldRoundUp ? 0 : hasHalfStar ? 1 : 0;
  const emptyStars = 5 - totalFullStars - totalHalfStars;

  return (
    <div
      className={`nc-StartRating flex items-center space-x-1 text-sm ${className}`}
      data-nc-id="StartRating"
    >
      {/* Full Stars - Filling from LEFT to RIGHT */}
      {[...Array(totalFullStars)].map((_, index) => (
        <SolidStarIcon key={index} className="w-5 h-5 text-yellow-500" />
      ))}

      {/* Half Star - Positioned correctly */}
      {totalHalfStars === 1 && (
        <div className="relative w-5 h-5">
          <SolidStarIcon className="w-5 h-5 text-yellow-500 absolute left-0" />
          <div className="absolute w-1/2 h-full bg-white right-0"></div>
        </div>
      )}

      {/* Empty Stars - Ensure total = 5 */}
      {[...Array(emptyStars)].map((_, index) => (
        <OutlineStarIcon key={index} className="w-5 h-5 text-gray-300" />
      ))}

      {/* Rating text */}
      <span className="font-medium">{(point ?? 0).toFixed(1)}</span>
      <span className="text-neutral-500 dark:text-neutral-400">
        ({reviewCount} {isHost ? "Places" : ""})
      </span>
    </div>
  );
};

export default StartRating;
