import React, { FC,useState,useEffect } from "react";
import NavigationItem from "./NavigationItem";
// import { NAVIGATION_DEMO } from "data/navigation";
// import axios from 'axios';  
export interface NavigationProps {
  menuData:any[];
}
const Navigation: FC<NavigationProps> = ({
  menuData
}) => {
  const [user_id,setUserId]                                   = useState("");
  const [defaultnegivation,setdefaultnegivation]              = useState(menuData);

  const isPositive = (value:any) =>{
    return value ;
  }
  const display = (num:any) =>{
      return num;
  }
  useEffect(() => {
    isPositive(menuData);
  }, [menuData]);//
 useEffect(() => {
  setdefaultnegivation(menuData.map(display).filter(isPositive));
}, []);//NAVIGATION_DEMO.map(display).filter(isPositive)

  return (
    <ul className="nc-Navigation hidden  hide">
      {menuData.map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}
export default Navigation;
