import React, { FC, ReactNode , useEffect, useState }  from "react";
import { useParams,useLocation  } from 'react-router-dom';
import StayCard from "components/StayCard/StayCard";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import axios from 'axios'; 
import { Helmet } from "react-helmet";

export interface FiltersState {
  experienceType: string[];
  amenities: string[];
  rooms: { beds: number; bedrooms: number; bathrooms: number };
}
export interface SectionGridFilterCardProps {
  className?: string;
  listings: any[]; // ✅ Receives listings from parent
  totalPages: number;
  totalRecords: number;
  currentpageno: number;
  pageTitle: string;
  pageDescription: string;
  paginationSlug: string;
  metaTitle: string;
  metaDescription: string;
  isLoading: boolean;
}

const DEMO_DATA: StayDataType[] = DEMO_STAY_LISTINGS.filter((_, i) => i < 8);
const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  listings = [],
  totalPages,
  totalRecords,
  currentpageno,
  pageTitle,
  pageDescription,
  paginationSlug,
  metaTitle,
  metaDescription,
  isLoading
}) => {

  const location = useLocation();

// ✅ Extract pathname and remove the first "/"
const baseUrl = location.pathname.startsWith("/") ? location.pathname.slice(1) : location.pathname;

//console.log("Base URL:", baseUrl);
  const page_urls = baseUrl;
  
  const renderskeletonLoader = () => {
      return (
        <div className="bg-white border relative rounded-2xl custom-loader w-full flex-none-loader">
          <div className="skeleton nc-StayCard group relative border border-neutral-100 rounded-2xl h-1/2 w-full"></div>
          <div className="w-full p-4 space-y-4">
            <div className="border border-neutral-100 group h-3 nc-StayCard relative rounded-2xl skeleton w-2/3 mb-3"></div>
            <div className="border border-neutral-100 group h-4 nc-StayCard relative rounded-2xl skeleton w-1/2"></div>
            <div className="border border-neutral-100 group h-3 nc-StayCard relative rounded-2xl skeleton w-full mb-2 mt-2"></div>
          </div>
            <div className="w-full p-4">
              <div className="gap-2 sm:grid grid-cols-3">      
              <div className="border border-neutral-100 h-6 rounded-2xl skeleton"></div>
              <div className="border border-neutral-100 h-6 rounded-2xl skeleton"></div>
              <div className="border border-neutral-100 h-6 rounded-2xl skeleton"></div>
              </div>
              <div className="skeleton nc-StayCard group relative border border-neutral-100 rounded-2xl mt-5 h-5 w-2/3"></div>
            </div>
        </div>
      );
  };

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Helmet title={metaTitle}></Helmet>
      <div className="mb-0 lg:mb-0 filter-model">
        <Heading2 totalPages={totalRecords} />
      </div> 
        {isLoading ?
          <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 G1">
          {listings.map((stay) => (
            <StayCard key={stay['id']} data={stay} />
          ))}
          </div>
          :
          <div className="grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 G1">
            {renderskeletonLoader()}
            {renderskeletonLoader()}
            {renderskeletonLoader()}
          </div>
        }
      <div className="flex mt-16 justify-center items-center">
        <Pagination count={totalPages} pageno={currentpageno.toString()} page_url={page_urls} queryString={true}/>
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
