import React, { FC } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

// Define the types for ProtectedRoute props
interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType<any>;
}
const ProtectedRoute: FC<ProtectedRouteProps> = ({ component: Component, ...rest }) => {
const isAuthenticated = !!localStorage.getItem("token"); // Replace with actual auth logic
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
};
export default ProtectedRoute;
