import React, { FC, Fragment, useState, useEffect, useRef } from "react";
import { Route, Redirect, useHistory, Link, useParams } from 'react-router-dom';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import CommonLayout from "./CommonLayout";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import NcImage from "shared/NcImage/NcImage";
import { Dialog, Transition } from "@headlessui/react";
import axios from 'axios';
import { Helmet } from "react-helmet";
import ReactPaginate from 'react-paginate';
import { FocusedInputShape } from "react-dates";
import ReservationDateRangeInput from "./ReservationDateRangeInput";
import Input from "shared/Input/Input";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import Pagination from "shared/Pagination/Pagination";
import { useAuth } from "hooks/useAuth"; // Adjust path if needed


export interface DateRage {
  startDate: moment.Moment | null;
  endDate: moment.Moment | null;
}

export interface AccountReservationsProps {
  className?: string;
}

const AccountReservations: FC<AccountReservationsProps> = ({
  className = "",
}) => {

  const history = useHistory();
  const [user_id, setUserId] = useState("");
  const [feature, setFeature] = useState([]);
  const [isdataLoaded, setIsdataLoaded] = useState(false);
  const [isOpenModalAddons, setIsOpenModalAddons] = useState(false);
  const [bookingcode, setbookingcode] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [starLimit, setStarLimit] = useState(0);
  const [endLimit, setEndLimit] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [pageRangeDisplay, setPageRangeDisplay] = useState(0);
  const [status, setStatus] = useState(0);
  const [activeTab, setActiveTab] = useState(() => {
    const savedTab = localStorage.getItem('activeTab');
    return savedTab ? savedTab : "all";
  });

  const [locationInputValue, setLocationInputValue] = useState("");
  const [dateFocused, setDateFocused] = useState<FocusedInputShape | null>(
    null
  );
  const { user, logout, updateUser } = useAuth();

  let search = window.location.search;
  let params = new URLSearchParams(search);
  let page = params.get('page');
  const pageNumber = page || 0;

  const [dateRangeValue, setDateRangeValue] = useState<DateRage>({
    startDate: null,
    endDate: null,
  });

  const PaginatedItems = (itemsPerPage: any) => {
    // Here we use item offsets; we could also use page offsets
    // following the API or data you're working with.
    const [itemOffset, setItemOffset] = useState(0);

    //setStarLimit(itemOffset);
    // Simulate fetching items from another resources.
    // (This could be items from props; or items loaded in a local state
    // from an API endpoint with useEffect and useState)
    //console.log("itemOffset"+itemOffset);
    const endOffset = itemOffset + parseInt(itemsPerPage.itemsPerPage);
    //setEndLimit(endOffset);
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    //const currentItemsss = items.slice(0, 4);
    const pageCount = Math.ceil(totalRecords / itemsPerPage.itemsPerPage);
    // Invoke when user click to request another page.
    const handlePageClick = (event: any) => {
      const newOffset = (event.selected * itemsPerPage.itemsPerPage) % totalRecords;
      console.log(
        `User requested page number ${event.selected}, which is offset ${newOffset}`
      );
      setItemOffset(newOffset);
      setStarLimit(newOffset);
      //setEndLimit(newOffset);
    };
    return (
      <>

      </>
    );
  }

  const handlerTabClick = (param: any) => {
    setActiveTab(param);
    setStatus(param);
    // Save the active tab to localStorage
    localStorage.setItem('activeTab', param.toString());

    const url = new URL(window.location.href);
    url.searchParams.delete('page'); // Remove the 'page' query parameter
    window.history.replaceState({}, '', url.toString());
  };

  useEffect(() => {
    const status = localStorage.getItem('activeTab');
    if (status) {
      setActiveTab(status);
    }
    setIsdataLoaded(true);
    axios.get(process.env.REACT_APP_BASE_URL + 'getBookings?user_id=' + user?.user_id + "&search_by=" + locationInputValue
      + "&checkin=" + (dateRangeValue.startDate ? dateRangeValue.startDate.format('YYYY-MM-DD') : "")
      + "&checkout=" + (dateRangeValue.endDate ? dateRangeValue.endDate.format('YYYY-MM-DD') : "")
      + "&page=" + pageNumber + "&status=" + status)
      .then(res => {
        console.log(res.data);
        setFeature(res.data.bookings);
        setTotalRecords(res.data.total_record);
        setTotalPages(res.data.total_pages);
        setPageRangeDisplay(res.data.page_range_display);
        setIsdataLoaded(true);
      })
  }, [user?.user_id, pageNumber, locationInputValue, dateRangeValue.startDate, dateRangeValue.endDate, status]);

  function closeModalViewAddons() {
    setIsOpenModalAddons(false);
  }
  function openModalViewAddons(booking_code: string) {
    axios.get(process.env.REACT_APP_BASE_URL + 'getaddons?booking_code=' + booking_code + "&user_id=" + user?.user_id + "&itemOffset=")
      .then(res => {
        setbookingcode(res.data.addpn_html)
      })
    setIsOpenModalAddons(true);
  }

  const ReservationCard = (feature: any) => {
    return (
      <>
        {feature && feature.length != 0 ?
          <div>
            {feature.map((featureItem: any) => (
              <>
                <div className="line-mid mt-5 mb-5"></div>
                <div className="flex">
                  <div className="w-70">
                    <div className="content">
                      <h4>{featureItem.listing_name}</h4>
                      <p>{featureItem.full_address}</p>
                      <div className="mb-20 mt-15  flex gap-2 mb-20 mt-15 coll-row">
                        <div className="flex gap-2 items-center"><i className="las la-calendar-alt font-size-20"></i><span> {featureItem.check_in_checkout_date}</span></div>
                        <div className="flex gap-2 items-center"><i className="las la-user-friends font-size-20"></i><span>{featureItem.total_guests} </span></div>
                        <div className="flex gap-2 items-center"><i className="las la-bed font-size-20"></i><span>{featureItem.beds} BED </span></div>
                        <div className="flex gap-2 items-center"><i className="las la-bath font-size-20"></i><span>{featureItem.bathrooms} BATH</span></div>
                      </div>

                    </div>
                    {/* <div className="content mt-10">
              <h4 className="mt-3 font-size-20">ADD ONS</h4>
              <div className="flex flex-row-add-on gap-4 grid grid-cols-1 gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2">
                <div className="flex gap-2">
                  <div className="w-70px"><img src="https://pictures.tinyaway.com/filters:format(jpg)/100x100/Listings/Images/2024/08/396/hsorkf72hwfup4dfnuj91724365347810.jpg" /></div>
                  <div className="w-100"><b>Pre-order food</b><p>Lunch - Large Pizza Set S$ 13 x 1</p></div>
              </div>
              <div className="flex gap-2">
              <div className="w-70px"><img src="https://pictures.tinyaway.com/filters:format(jpg)/100x100/Listings/Images/2024/08/396/hsorkf72hwfup4dfnuj91724365347810.jpg" /></div>
                  <div className="w-100"><b>Pre-order food</b><p>Dinner - Spaghetti set S$ 25 x 2</p></div>
              </div>
              </div>
              </div> */}
                    <div className="content mt-10">
                      <h4>BOOKING DETAILS</h4>
                      <div className="flex gap-20 table-row-2">
                        <div className="w-100px">BOOKING CODE </div><div>#{featureItem.confirmation_code} </div>
                      </div>
                      <div className="flex gap-20 table-row-2">
                        <div className="w-100px">DATE </div><div>{featureItem.booked_date} </div>
                      </div>
                      <div className="flex gap-20 table-row-2">
                        <div className="w-100px">TOTAL AMOUNT </div><div>{featureItem.total_payout} </div>
                      </div>
                      <div className="flex gap-20 table-row-2">
                        <div className="w-100px">PAYMENT METHOD</div><div>{featureItem.payment_method}</div>
                      </div>
                    </div>
                  </div>
                  <div className="w-30">
                    <div className="right-box-add-on">
                      <p className="confirmed">Booking {featureItem.booking_status}</p>
                      <img src={featureItem.listing_image} />
                      <a href="" className="btn btn-box-css">Share booking</a>
                      <div className="star flex star-row">
                        <FiveStartIconForRate iconClass="w-4 h-4" className="space-x-0.5" />
                      </div>
                      <p className="rate-color font-size-20">TAP TO RATE</p>
                      <a href="" className="btn btn-box-theme">Request to cancel</a>
                    </div>
                  </div>
                </div>
              </>
            ))}



          </div>
          :
          <>
            <p>
              No Record Found!
              {/* <div className="skeleton-container">
      <div className="skeleton-header">
        <div className="skeleton-title"></div>
        <div className="skeleton-subtitle"></div>
      </div>
      <div className="skeleton-content">
        <div className="skeleton-info">
          <div className="skeleton-section-title"></div>
          <div className="skeleton-text"></div>
          <div className="skeleton-text"></div>
          <div className="skeleton-text"></div>
          <div className="skeleton-text"></div>
          <div className="skeleton-text"></div>
          <div className="skeleton-text"></div>
        </div>
        <div className="skeleton-image-container">
          <div className="skeleton-text skeleton-small"></div>
          <div className="skeleton-image"></div>
        </div>
      </div>
      <div className="skeleton-actions">
        <div className="skeleton-button"></div>
        <div className="skeleton-stars"></div>
      </div>
      <div className="skeleton-cancel-button"></div>
      </div> */}
            </p>
          </>
        }
      </>
    );
  }
  const regervesonPageneson = () => {
    return (
      <div className="flex mt-4 justify-center items-center">
        <Pagination count={totalPages} pageno={pageNumber?.toString()}
          page_url="reservations"
        />
      </div>
    )


  }

  const renderMotalViewAddons = () => {
    return (
      <div className="right-user-details">
        <h4>Reservations</h4>
        <div className="list-tab-account">
          <a href="javascript:void(0)" onClick={() => handlerTabClick('all')} className={activeTab === 'all' ? 'active' : ''}>All bookings</a>
          <a href="javascript:void(0)" onClick={() => handlerTabClick('active')} className={activeTab === 'active' ? 'active' : ''}>Active</a>
          <a href="javascript:void(0)" onClick={() => handlerTabClick('past')} className={activeTab === 'past' ? 'active' : ''}>Past</a>
          <a href="javascript:void(0)" onClick={() => handlerTabClick('cancelled')} className={activeTab === 'cancelled' ? 'active' : ''}>Cancelled</a>
        </div>

        {ReservationCard(feature)}

      </div>

    );
  };
  return (
    <div className="bgcolor-light pb-14 pt-10">
      <Helmet title="Tiny Away | Reservations"></Helmet>
      <div className="container">

        <div className="row-profile  mt-5 mb-5">

          <CommonLayout />

          <div className="regervesonPagenesonFull-page">
            {renderMotalViewAddons()}
            {regervesonPageneson()}
          </div>




        </div>
      </div>
    </div>
  );
};
export default AccountReservations;
