import React, { FC, useState } from 'react';
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionAibnbReviews from "components/SectionAibnbReviews/SectionAibnbReviews";
import { TaxonomyType } from "data/types";
import axios from 'axios';

const DEMO_CATS: TaxonomyType[] = [
    {
      id: "1",
      href: "#",
      name: "New Yourk",
      taxonomy: "category",
      count: 188288,
      thumbnail:
        "https://cdn.tinyaway.com/common/BI_blue_background_vertical.png",
    },
    {
      id: "2",
      href: "#",
      name: "New Yourk",
      taxonomy: "category",
      count: 188288,
      thumbnail:
        "https://cdn.tinyaway.com/common/761ec63ba823cd88446152f20d7d8183.png",
    },
    {
      id: "3",
      href: "#",
      name: "New Yourk",
      taxonomy: "category",
      count: 188288,
      thumbnail:
        "https://cdn.tinyaway.com/common/7-NEWS-black.png",
    },
    {
      id: "4",
      href: "#",
      name: "New Yourk",
      taxonomy: "category",
      count: 188288,
      thumbnail:
        "https://cdn.tinyaway.com/common/Stuff.png",
    },
    {
      id: "5",
      href: "#",
      name: "New Yourk",
      taxonomy: "category",
      count: 188288,
      thumbnail:
        "https://cdn.tinyaway.com/common/Urban-List.png",
    },
    {
      id: "6",
      href: "#",
      name: "New Yourk",
      taxonomy: "category",
      count: 188288,
      thumbnail:
        "https://cdn.tinyaway.com/common/timeout-cs-logo-img-1.jpg",
    },
    {
      id: "7",
      href: "#",
      name: "New Yourk",
      taxonomy: "category",
      count: 188288,
      thumbnail:
        "https://cdn.tinyaway.com/common/the-weekend-Australian-magazine-1.png",
    },
    {
      id: "8",
      href: "#",
      name: "New Yourk",
      taxonomy: "category",
      count: 188288,
      thumbnail:
        "https://cdn.tinyaway.com/common/daily-telegraph-logo-vector-i1-1.png",
    },
    {
      id: "9",
      href: "#",
      name: "New Yourk",
      taxonomy: "category",
      count: 188288,
      thumbnail:
        "https://cdn.tinyaway.com/common/tfe-logo-768x123-2.png",
    }
  ];

export interface SectionFeedbackForm {
  className?: string;
}

const SectionFeedbackForm: FC<SectionFeedbackForm> = ({ className = "" }) => {
    const [details, setDetails] = useState({
        name: "",
        email: "",
        contactnumber: "",
        subject: "Keep in Touch with Tiny Away",
        message: "",
    });
    const [errorMsg, setErrorMsg] = useState('');
    const [successMsg, setSuccessMsg] = useState('');

      const [formErrors, setFormErrors] = useState({
        name: '',
        email: '',
        contactnumber: '',
        subject: '',
        message: ''
      });
    
      const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = e.target;
        setDetails(prevState => ({
          ...prevState,
          [name]: value
        }));
      };
    
      // Handle keyup to reset errors
      const handleKeyUp = (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name } = e.target as HTMLInputElement;
        setFormErrors(prevState => ({
          ...prevState,
          [name]: ''  // Reset specific error for the input field on keyup
        }));
      };

      // Simple validation function
  const validate = () => {
    const errors: any = {};
    let isValid = true;

    if (!details.name) {
      errors.name = "Name is required";
      isValid = false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!details.email || !emailRegex.test(details.email)) {
      errors.email = "Valid email is required";
      isValid = false;
    }

    if (!details.contactnumber) {
      errors.contactnumber = "Contact number is required";
      isValid = false;
    }

    if (!details.subject) {
      errors.subject = "Subject is required";
      isValid = false;
    }

    if (!details.message) {
      errors.message = "Message cannot be empty";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  // Form submit handler
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    // Validate form before submission
    if (validate()) {
      // Send form data to the server
      try {
        axios.post(process.env.REACT_APP_BASE_URL + '/customer_enquiries', details)
        .then(function (response) {
          //console.log(response.data.response);
          if (response.data.status == 200) {
            setSuccessMsg(response.data.response);
            setDetails({
              name: "",
              email: "",
              contactnumber: "",
              subject: "",
              message: ""
            });
          } else {
            setErrorMsg(response.data.response);
          }
        })
        .catch(function (error) {
          console.log(error);
        });

        // Reset form after successful submission
        setDetails({
          name: '',
          email: '',
          contactnumber: '',
          subject: '',
          message: ''
        });
        alert('Form submitted successfully');
      } catch (error) {
        console.error('Error submitting form:', error);
        alert('Error submitting form');
      }
    }
  };


  return (
    <div className="relative">
    <img className="top-5" src="https://pictures.tinyaway.com/filters:format(jpg)/1920x800/Listings/Images/2024/05/1/gwcisxhbjhxgshvrlxbf1714967317336.jpg" />
    <div className="hearing-from-content container">
      <div className="left-part-hedding mt-100">
        <h4>WE LOVE HEARING FROM YOU</h4>
        <p>If you have any questions or feedback, we're here to help.</p>
      </div>

      <div className="right-part-hedding mt-16">
        <div className="flex justify-center">
          <ButtonPrimary type="submit" className="btn-box-form"><img src="https://pictures.tinyaway.com/filters:format(svg)/icons/contactform_top_icon.svg" /></ButtonPrimary>
        </div>
        <div className="hearing-form-group">
          <h5>To Discover Nature and Stay in Comfort.</h5>
          <form className="grid grid-cols-1 gap-6" action="#" onSubmit={handleSubmit}  autoComplete="off" method="post">
            <label className="block">
              <Input
                type="text"
                placeholder="NAME"
                name="name"
                id="name"
                autoComplete="off"
                onChange={handleInputChange}
                onKeyUp={handleKeyUp} // Add keyup event handler
                value={details.name}
              />
              {formErrors.name && <span className="text-red-500">{formErrors.name}</span>}
            </label>
            <label className="block">
              <Input
                type="email"
                placeholder="EMAIL"
                name="email"
                id="email"
                autoComplete="off"
                onChange={event => setDetails({ ...details, email: event.target.value })}
                value={details.email}
              />
              {formErrors.email && <span className="text-red-500">{formErrors.email}</span>}
            </label>
            <label className="block">
              <Input
                type="text"
                placeholder="CONTACT NUMBER"
                name="contactnumber"
                id="contactnumber"
                autoComplete="off"
                onChange={event => setDetails({ ...details, contactnumber: event.target.value })}
                value={details.contactnumber}
              />
              {formErrors.contactnumber && <span className="text-red-500">{formErrors.contactnumber}</span>}

            </label>
            <label className="block">
              <Input
                type="text"
                placeholder="SUBJECT"
                name="subject"
                id="subject"
                autoComplete="off"
                onChange={event => setDetails({ ...details, subject: event.target.value })}
                value={details.subject}
              />
             {formErrors.subject && <span className="text-red-500">{formErrors.subject}</span>}

            </label>
            <label className="block">
              <Textarea placeholder="YOUR MESSAGE"
                name="message"
                id="message"
                autoComplete="off"
                onChange={event => setDetails({ ...details, message: event.target.value })}
                value={details.message} rows={6}
              />
             {formErrors.message && <span className="text-red-500">{formErrors.message}</span>}

            </label>
            <div>
              {/* <ReCAPTCHA
                // size="invisible"
                sitekey={REACT_APP_SITE_KEY!}
                ref={captchaRef}
                id="google_captcha"
                onChange={SetRecaptchaVal}
              /> */}
            </div>
            <div className="mb-0 text-center">
              <ButtonPrimary type="submit">Submit</ButtonPrimary>
            </div>
          </form>
        </div>
      </div>
    </div>
    <div className="border-2 w-full clint-logo-about">
      <div className="container">
        <SectionAibnbReviews
          heading="featured In "
          subHeading=""
          className="hedding-center sm-hedding-text hedding-aboutus"
          categoryCardType="card6"
          categories={DEMO_CATS}
          itemPerRow={4}
        />
      </div>
    </div>
  </div>
  );
};

export default SectionFeedbackForm;
