import React, { FC, ReactNode , useEffect, useState }  from "react";
import { useParams,useLocation } from 'react-router-dom';
import AnyReactComponent from "components/AnyReactComponent/AnyReactComponent";
import StayCardH from "components/StayCardH/StayCardH";
import GoogleMapReact from "google-map-react";
import { DEMO_STAY_LISTINGS } from "data/listings";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Checkbox from "shared/Checkbox/Checkbox";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import HeadingMap from "components/Heading/HeadingMap";
import axios from 'axios';  
import ButtonPrimary from "shared/Button/ButtonPrimary"; 
const DEMO_STAYS = DEMO_STAY_LISTINGS.filter((_, i) => i < 12);
export interface FiltersState {
  experienceType: string[];
  amenities: string[];
  rooms: { beds: number; bedrooms: number; bathrooms: number };
}
export interface SectionGridHasMapProps {
  className?: string;
  listings: any[]; // ✅ Receives listings from parent
  totalPages: number;
  totalRecords: number;
  currentpageno: number;
  pageTitle: string;
  pageDescription: string;
  paginationSlug: string;
  metaTitle: string;
  metaDescription: string;
  isLoading: boolean;
}
const SectionGridHasMap: FC<SectionGridHasMapProps> = ({
  className = "",
  listings = [],
  totalPages,
  totalRecords,
  currentpageno,
  pageTitle,
  pageDescription,
  paginationSlug,
  metaTitle,
  metaDescription,
  isLoading
}) => {
  
  const [currentHoverID, setCurrentHoverID]         = useState<string | number>(-1);
  const [showFullMapFixed, setShowFullMapFixed]     = useState(false);
  const page_urls = `book-a-tiny-house`;
let mapcenter = {lat:-37.3218879, lng: 145.3109719};
if(listings.length > 0){
  mapcenter     = listings[0]['map'];
}

  const renderskeletonLoader = () => {
    return (
      <div className="bg-white border relative rounded-2xl custom-loader w-full  stories-loader">
        <div className="skeleton nc-StayCard group relative border border-neutral-100 rounded-2xl h-1/2 w-full"></div>
        <div className="w-full p-4 space-y-4">
        <div className="border border-neutral-100 group h-2 nc-StayCard relative rounded-2xl skeleton w-2/3 mb-3"></div>
        <div className="border border-neutral-100 group h-4 nc-StayCard relative rounded-2xl skeleton w-1/2"></div>
        <div className="border border-neutral-100 group h-2 nc-StayCard relative rounded-2xl skeleton w-2/3 mb-0 mt-2"></div>
        </div>
        <div className="w-full p-4 pt-0 mb-0 mt-3">
        <div className="gap-2 sm:grid grid-cols-3">      
        <div className="border border-neutral-100 h-4 rounded-2xl skeleton"></div>
        <div className="border border-neutral-100 h-4 rounded-2xl skeleton"></div>
        <div className="border border-neutral-100 h-4 rounded-2xl skeleton"></div>
        </div>
        </div>
        <div className="flex justify-content-between  p-4 pt-0 mb-0 mt-3">
        <div className="border border-neutral-100 h-3 rounded-2xl skeleton w-1/100"></div>
        <div className="border border-neutral-100 h-3 rounded-2xl skeleton w-1/100"></div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <div className="relative flex min-h-screen ml-21 gap-5 mt-10">
        
        <div className="min-h-screen w-full  flex-shrink-0  w-2/3 xs-768 pl-0 px-6">
          <div className="mb-0 lg:mb-0 filter-model">
            <HeadingMap totalPages={totalRecords} />
          </div> 
          {isLoading ?
          <div className="grid grid-cols-2  gap-5 sm-grid-cols-1">
            {listings.map((item) => (
              <div key={item['id']}
                onMouseEnter={() => setCurrentHoverID((_) => item['id'])}
                onMouseLeave={() => setCurrentHoverID((_) => -1)}>
                <StayCardH data={item} />
              </div>
            ))}
          </div>
          :
          <div className="grid grid-cols-2  gap-5 sm-grid-cols-1">
            {renderskeletonLoader()}
            {renderskeletonLoader()}
          </div>
          }
          <div className="flex mt-16 justify-center items-center">
          <Pagination count={totalPages} pageno={currentpageno.toString()} page_url={page_urls} queryString={true}
        />
          </div>
        </div>
        {/* {!showFullMapFixed && (
          <div
            className="flex  items-center justify-center fixed bottom-8 right-1 transform -translate-x-1/2 px-6 py-2 bg-neutral-900 text-white shadow-2xl rounded-full z-30  space-x-3 text-sm cursor-pointer"
            onClick={() => setShowFullMapFixed(true)}
          >
            <i className="text-lg las la-map"></i>
            <span>Show map</span>
          </div>
        )} */}

        {/* MAPPPPP */}
        <div
          className={`xl:flex-grow xl:static xl:block ${
            showFullMapFixed ? "fixed inset-0 z-50" : "hidden"
          }`}
        >
          {showFullMapFixed && (
            <ButtonClose
              onClick={() => setShowFullMapFixed(false)}
              className="bg-white absolute z-50 left-3 top-3 shadow-lg rounded-xl w-10 h-10"
            />
          )}

          <div className="fixed xl:sticky top-0 xl:top-[0px] left-0 w-full h-full xl:h-[calc(100vh-88px)] rounded-md overflow-hidden">
            {/* <div className="absolute bottom-5 left-3 lg:bottom-auto lg:top-2.5 lg:left-1/2 transform lg:-translate-x-1/2 py-2 px-4 bg-white shadow-xl z-10 rounded-2xl min-w-max">
              <Checkbox
                className="text-xs xl:text-sm"
                name="xx"
                label="Search as I move the map"
              />
            </div> */}
            {/* BELLOW IS MY GOOGLE API KEY -- PLEASE DELETE AND TYPE YOUR API KEY */}
            <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyCxywmWV_Bz1FH-NgAgc-wwcVcD0-c8XYQ",
              }}
              defaultZoom={5}
              yesIWantToUseGoogleMapApiInternals
              defaultCenter={mapcenter}
              center={mapcenter}
            >
              {listings.map((item) => (
                <AnyReactComponent
                  isSelected={currentHoverID === item['id']}
                  key={item['id']}
                  lat={item['map']['lat']}
                  lng={item['map']['lng']}
                  listing={item}
                />
              ))}
            </GoogleMapReact>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionGridHasMap;
