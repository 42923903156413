import React, { FC, Fragment, ReactNode, useEffect, useState, useRef } from "react";
import { useParams,useHistory } from 'react-router-dom';
import { ArrowRightIcon } from "@heroicons/react/outline";
//mport LocationMarker from "components/AnyReactComponent/LocationMarker";
import AnyReactThingsComponent from "components/AnyReactComponent/AnyReactThingsComponent";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import GuestsInput from "components/HeroSearchForm/GuestsInput";
import StartRating from "components/StartRating/StartRating";
import GoogleMapReact from "google-map-react";
import useWindowSize from "hooks/useWindowResize";
import moment from "moment";
import { DayPickerSingleDateController } from "react-dates";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import NcImage from "shared/NcImage/NcImage";
import LikeSaveBtns from "./LikeSaveBtns";
import ModalPhotos from "./ModalPhotos";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import ExperiencesDateSingleInput from "components/HeroSearchForm/ExperiencesDateSingleInput";
import GallerySlider from "components/GallerySlider/GallerySlider";
import StayDatesRangeInput from "components/HeroSearchForm/StayDatesRangeInput";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import axios from 'axios'; 
import { Helmet } from "react-helmet";

export interface ListingExperiencesDetailPageProps {
  className?: string;
  ratioClass?: string;
  DefaultUserID?: number;
}


const galleryImgs:string[]=[
  "https://pictures.tinyaway.com/filters:format(jpg)/450x300/Stories/Images/2022/05/124/Tiny-Away-800-011653897785167.jpg",
  "https://pictures.tinyaway.com/filters:format(jpg)/450x300/Stories/Images/2022/05/124/Tiny-Away-800-011653897785167.jpg",
  "https://pictures.tinyaway.com/filters:format(jpg)/450x300/Stories/Images/2022/05/124/Tiny-Away-800-011653897785167.jpg"

];
const includes_demo = [
  { name: "Set Menu Lunch on boat" },
  { name: "Express Bus From Hanoi To Halong and Return" },
  { name: "Mineral Water On Express Bus" },
  { name: "Kayak or Bamboo Boat. Life Jacket." },
  { name: "Halong Bay Entrance Ticket" },
];
console.log(11, moment());

const ListingExperiencesDetailPage: FC<ListingExperiencesDetailPageProps> = ({
  className = "",
  ratioClass = "aspect-w-3 aspect-h-2",
  DefaultUserID = localStorage.getItem("user_id") ? localStorage.getItem("user_id") : 0
}) => {
  const history = useHistory();
  const params                                      = useParams();
  // yuo can find all params from here
  const slug                                        = Object.values(params);
  const [isOpen, setIsOpen]                         = useState(false);
  const [openFocusIndex, setOpenFocusIndex]         = useState(0);
  const [datall, setAatall]                         = useState([]);
  const [stoty_title, setStorytitle]                = useState([]);
  const [infuencername, setInfuencername]           = useState([]);
  const [PHOTOS, setPHOTOS]                         = useState([]);
  const [descriptions, setDescriptions]             = useState("");
  const [listingName, setListingName]               = useState("");
  const [address, setAddress]                       = useState("");
  const [mapcoordinates, setMapcoordinates]         = useState({
    "lat": -37.3218879,
    "lng": 145.3109719
});
  const [maplat, setMaplat]                                   = useState(-37.3218879);
  const [maplng, setMaplng]                                   = useState(145.3109719);
  const [mapzoom, setMapzoom]                                 = useState(6);
  const [thingsWeDid, setThingsWeDid]                         = useState([]);
  const [metatitle, SetMetatitle]                             = useState([]);
  const [metadata, SetMetaData]                               = useState([]);
  const [influencer_pic, setInfluencerpic]                    = useState("");
  const [listingFeaturedImg, setListingFeaturedImg]           = useState("");
  const [listingURL, setListingURL]                           = useState("");
  const [currency_code, SetCurrencycode]             = useState("");
  const [listingPrice, SetListingPrice]             = useState("");
  const [selectedDate, setSelectedDate] = useState<DateRage>({
    /*startDate: moment(),
    endDate: moment().add(1, "days"),*/
    startDate: null,
    endDate: null,
  });
  const [adults, setAdults] = useState(2);
  const [children, setChildren] = useState(0);
  const [infants, setInfants] = useState(0);
  const [guestyListingId, setguestyListingId] = useState("");
  const [checkInDetails, setCheckInDetails] = useState({ checkInCheckOutDate: { startDate: selectedDate.startDate, endDate: selectedDate.endDate }, listing_id: guestyListingId, adults: adults, children: children, infants: infants });
  
  const [calendarMessage, setCalendarMessage] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [availableDates, setavailableDates] = useState("");
  const [checkoutOnlyDates, CheckoutOnlyDates] = useState("");
  const [maxguest, setMaxguest] = useState(0);
  const [maxAdults, setMaxAdults] = useState(0);
  const [maxChilds, setMaxChilds] = useState(0);
  const [isOpenModalLoginSignUp, setIsOpenModalLoginSignUp] = useState(false);
  const [priceBreakDown, setPriceBreakDown] = useState("");
  
  //const [thingsHref, setThingsHref]                 = useState("");

console.log(process.env.REACT_APP_BASE_URL+'/GetStoriesDetails?id='+slug);
  useEffect(() => {
   
    axios.get(process.env.REACT_APP_BASE_URL+'/GetStoriesDetails?id='+slug)
 	 .then(res => {
          console.log('user effect0000'); 
	      	console.log(res.data); 
          //console.log(res.data.title);
          console.log(res.data.author_pic);
          setAatall(res.data);
          setStorytitle(res.data.title);
          setInfuencername(res.data.influencer_name);
          setPHOTOS(res.data.galleryImgs);
          setDescriptions(res.data.publicDescription);
          setListingName(res.data.listing_name);
          setAddress(res.data.address);
          setMaplat(res.data.map.lat);
          setMaplng(res.data.map.lng);
          setMapzoom(11);
          setMapcoordinates(res.data.map);
          setThingsWeDid(res.data.thins_we_did);
          SetMetatitle(res.data.meta_title);
          SetMetaData(res.data.meta_data);
          setInfluencerpic(res.data.influencer_pic);
          setListingFeaturedImg(res.data.listing_featured_image);
          setListingURL(res.data.href);
          SetListingPrice(res.data.price);
          SetCurrencycode(res.data.currency_code);
          //setThingsHref(res.data.influencer_pic);
    });   
  }, []);
  

  const windowSize = useWindowSize();

  const getDaySize = () => {
    if (windowSize.width <= 375) {
      return 34;
    }
    if (windowSize.width <= 500) {
      return undefined;
    }
    if (windowSize.width <= 1280) {
      return 56;
    }
    return 48;
  };

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const renderSliderGallery = () => {
    return (
      
      <div className="relative w-full rounded-2xl overflow-hidden">
        <GallerySlider
          uniqueID={`ExperiencesCard-1`}
          ratioClass={ratioClass}
          galleryImgs={galleryImgs}
        />
      </div>
    );
  };
  function OpenModalLoginSignUp() {
    setIsOpenModalLoginSignUp(true);
  }
  function openModalBookingForm() {
  }

  function closeModalBookingForm() {
  }
  
const renderAddToCart = () =>{
  return (
  <div className="block fixed sticky bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20">
          <div className="container flex items-center justify-between">
            <span className="custome-search-style-sticy">
            <h2 className="uppercase">{listingName}</h2>
            <div >
            <span className="uppercase">{currency_code}{listingPrice}</span>  <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400"> /night </span>
              </div>
            </span>
              <div className="w-full renderSidebar-custom">{renderSidebar()}</div>
          </div>
        </div>
  )
}

const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
  event.preventDefault();




  if (selectedDate?.startDate != null && selectedDate?.endDate != null) {
    if (guestyListingId != '') {
      console.log({ checkInDetails, selectedDate });
      // setCheckInDetails({...checkInDetails,checkInCheckOutDate:selectedDate});
      axios.post(process.env.REACT_APP_BASE_URL + 'addToCart', {
        checkInCheckOutDate: { startDate: selectedDate?.startDate.format("YYYY-MM-DD"), endDate: selectedDate?.endDate.format("YYYY-MM-DD") },
        listing_id: guestyListingId,
        adults: adults,
        children: children,
        infants: infants,
        user_id: DefaultUserID
      }).then(res => {
        if (res.data.status == 401) {
          setCalendarMessage(res.data.response);
        }
        if (res.data.status == 200) {
          history.push("/checkout");
          history.go(0);
        }
      });
    }
  } else {
    setCalendarMessage("Start and end date required");
  }
}


  const renderSection1 = () => {
    return (
      <div className=" !space-y-6">
        {/* 1 */}
        {/* <div className="flex justify-between items-center">
          <Badge color="pink" name="Specific Tour" />
          <LikeSaveBtns />
        </div> */}

        {/* 2 */}
       

        {/* 3 */}
        {/* <div className="flex items-center space-x-4">
          <StartRating />
          <span>·</span>
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> Tokyo, Jappan</span>
          </span>
        </div> */}

        {/* 4 */}
        <div className="flex">
        <div className="flex items-center flex-direction-column w-30">
        <Avatar hasChecked sizeClass="h-28 w-28" radius="rounded-full"  avatarImage={influencer_pic} />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400 mt-3">
          Story by{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-medium">
              {infuencername}
            </span>
          </span>
        </div>

        {/* 5 */}
        <div className="w-70">
          <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold text-left mb-4">
            {stoty_title}
          </h2>
          <div className="flex flex-title-add-name gap-2 mb-4">
          <span className="block mt-0 text-bold dark:text-bold uppercase">
          {listingName}
          </span>
          <span className="line"></span>
          <span className="block mt-0 text-neutral-500 dark:text-neutral-400 uppercase">
            {address}
          </span>
          </div>
          <div className="story-description" dangerouslySetInnerHTML={ {__html: descriptions} } />
        </div>
        </div>
        </div>
    );
  };

  const renderSection2 = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Experiences descriptions</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <p>
            TRANG AN BOAT TOUR & MUA CAVE CLIMBING TOUR FROM HANOI
            <br />
            <br />
            07:30 – 08:00 – Our guide will meet you at your hotel/stay and start
            a 120km comfortable Limousine bus journey through the verdant
            landscape. Stopover for a rest on the way.
            <br />
            <br />
            BAI DINH PAGODA EXPLORER.
            <br />
            <br />
            10:30 – Arrive Bai Dinh pagoda complex, get on electric cars to
            visit massive architecture.
            <br />
            <br />
            12:15 – Enjoy the buffet lunch at our restaurant, a great place to
            savor the flavours of Vietnamese food.
            <br />
            <br />
            TRANG AN TOUR ON BOAT.
            <br />
            <br />
            13:30 – Visit Trang An Grottoes, get on a rowing boat traveling
            along the river with scenic mountain and green fields landscape.
            <br />
            <br />
            MUA CAVE HIKING. TAKE PICTURE
            <br />
            <br />
            15:45 – Arrive at Mua Cave and start an amazing trek up to the top
            of Ngoa Long mountain.
            <br />
            <br />
            17:30 – 20:00 – Return to our Limousine bus and then come back to
            Hanoi. Drop you off at your hotel/stay. Other things to note
            <br />
            <br />
            It is one full day tour. Start from 07.30 AM and finish at 20.00. We
            just put one hour and default departure time because we have many
            other tours. IF you need any further details
          </p>
        </div>
      </div>
    );
  };

  const renderSection3 = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Include </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Included in the price
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* 6 */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 text-sm text-neutral-700 dark:text-neutral-300 ">
          {includes_demo
            .filter((_, i) => i < 12)
            .map((item) => (
              <div key={item.name} className="flex items-center space-x-3">
                <i className="las la-check-circle text-2xl"></i>
                <span>{item.name}</span>
              </div>
            ))}
        </div>
      </div>
    );
  };



  const renderSection5 = () => {
    return (
     <div>
<div className="flex">
  <div className="w-30">
  <div className="title-page">
  <h4>WHERE WE STAYED</h4></div>
  </div>
  <div className="w-70">List card</div>
</div>
     </div>
    );
  };

  const renderSection6 = () => {
    return (
      <div className="flex">
        <div className="w-30">
          <div className="title-page">
            <h4>THINGS WE DID</h4></div>
            <div className="list-icon-linstin-page">
              <a href="" className="flex items-center"><i className="Waterfall"></i> Waterfall </a>
              <a href="" className="flex items-center"><i className="Lookout"></i>  Lookout </a>
              <a href="" className="flex items-center"><i className="food-drink"></i> Food & Drink </a>
              <a href="" className="flex items-center"><i className="Forest"></i>  Forest</a>
            </div>
        </div>
        <div className="w-70">
          {renderSectionThingsWeDid()}
        </div>
      </div>
    );
  };

  const renderSection7 = () => {
    return (
      <div>
        
      <div className="flex">
        {/* HEADING */}
        <div className="w-30">
        <div className="">
        <div className="title-page">
        <h4>OUR ADVENTURE MAP</h4></div>
          {/* <h2 className="text-2xl font-semibold">{listingName}</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {address}
          </span> */}
        </div>

        <div className="flex-direction-column gap-3 icon-name-location items-start justify-start">
        <span className="icon-ms-thins gap-2 color-yellw"><i className="icon-Romantic Getaway"></i> Where we stayed</span>
        <span className="icon-ms-thins gap-2 color-gry"><i className="icon-Romantic Getaway"></i> Things we did</span>

        {/* <span><i className="las la-home"></i> Accommodation</span>
        <span><i className="las la-hiking text-lg"></i> Places we visited</span> */}
        </div>
        </div>
        {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" /> */}

        {/* MAP */}
        <div className="w-70 h-400"> 
          <p className="font-size-17 mb-2">We've plotted our adventure on the map below. Select the map icons to discover more details.</p>
        <GoogleMapReact
              bootstrapURLKeys={{
                key: "AIzaSyCxywmWV_Bz1FH-NgAgc-wwcVcD0-c8XYQ",
              }}
              defaultZoom={mapzoom}
              zoom={mapzoom}
              yesIWantToUseGoogleMapApiInternals
              defaultCenter={mapcoordinates}
              center={mapcoordinates}
            >
              {/* <LocationMarker lat={maplat} lng={maplng} /> */}
              
              <AnyReactThingsComponent
                  lat={maplat}
                  lng={maplng}
                  icontype="home"
                />
              {thingsWeDid.map((items) => (
                 <AnyReactThingsComponent
                 key={items['id']}
                  lat={items['map']['lat']}
                  lng={items['map']['lng']}
                  listing={items}
                  icontype="things"
                />
              ))}
            </GoogleMapReact>
        
        </div>
      </div>
      </div>
    );
  };

  const renderSectionThingsWeDid = () => {
    return (
      <div className="grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 listing-things ">
        {/* HEADING */}
        {thingsWeDid.map((itemDetails) => (
            <div className={`flex-right`}>
                <div className="sld-Things">
                <div className="relative w-full rounded-2xl overflow-hidden">
                  <GallerySlider
                    uniqueID={`ExperiencesCard-${itemDetails['id']}`}
                    ratioClass={ratioClass}
                    galleryImgs={itemDetails['ThingsgalleryImgs']}
                  />
                </div>
                </div>
                <div className="text-Things">
                  <div>
                  <h4><a href={itemDetails['things_href']}>
                    <div className="flex gap-1 items-center">
                    <span> <i className="icon-Romantic"></i> </span> <div className="line-clamp-1">{itemDetails['title']}</div></div>
                    </a></h4>
                  {/* <div>
                    <span className="flex items-center mb-2">
                     <span className="icon-hiking">
                      <i className="las la-hiking text-lg"></i> </span>
                     <span>{itemDetails['address']}</span> 
                    </span>
                  </div> */}
                  <div className="flex flex-wrap" dangerouslySetInnerHTML={ {__html: itemDetails['description']} } />
                </div>
                {/* things to do  START*/}
                {/* <div className="icons-thins-to-know">
                {[...itemDetails['categories']].map((catitems:any) => (
                    <div className={`list-icon-all ${catitems}`}>
                        <span className="icon-ms-thins">
                          <i className={`icon-${catitems}`}></i>
                        </span>
                        <span>
                            <h5>{catitems}</h5>
                        </span>
                    </div>
                ))}
                </div> */}
                </div>
            </div>
        ))}
      </div>
    );
  };
  const renderSection9 = () => {
    return (
     <div className="flex">
      <div className="w-30">
      <div className="title-page">
      <h4 className="uppercase">Features And Amenities</h4>
      <h5>THE SPACE</h5>
      </div>
      </div>
      <div className="w-70 mb-5">
      Icon lIst Here
      </div>
     </div>
    );
  };

  // const renderSidebar = () => {
  //   return (
  //     <div className="listingSection__wrap shadow-xl">
  //       {/* PRICE */}
  //       <div className="flex justify-between">
  //         <span className="text-3xl font-semibold">
  //           $19
  //           <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
  //             /person
  //           </span>
  //         </span>
  //         <StartRating isHost={false} />
  //       </div>

  //       {/* FORM */}
  //       <form className="flex border divide-x divide-neutral-200 dark:divide-neutral-700 border-neutral-200 dark:border-neutral-700 rounded-3xl ">
  //         <div className="flex-1">
  //           <ExperiencesDateSingleInput
  //             defaultValue={selectedDate}
  //             onFocusChange={() => {}}
  //             onChange={(date) => setSelectedDate(date)}
  //             anchorDirection={windowSize.width > 1400 ? "left" : "right"}
  //             fieldClassName="p-5"
  //           />
  //         </div>
  //         <div className="flex-1">
  //           <GuestsInput
  //             fieldClassName="p-5"
  //             defaultValue={{
  //               guestAdults: 1,
  //               guestChildren: 2,
  //               guestInfants: 0,
  //             }}
  //           />
  //         </div>
  //       </form>

  //       {/* SUM */}
  //       <div className="flex flex-col space-y-4">
  //         <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
  //           <span>$19 x 3 adults</span>
  //           <span>$57</span>
  //         </div>
  //         <div className="flex justify-between text-neutral-6000 dark:text-neutral-300">
  //           <span>Service charge</span>
  //           <span>$0</span>
  //         </div>
  //         <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
  //         <div className="flex justify-between font-semibold">
  //           <span>Total</span>
  //           <span>$199</span>
  //         </div>
  //       </div>

  //       {/* SUBMIT */}
  //       <ButtonPrimary>Reserve</ButtonPrimary>
  //     </div>
  //   );
  // };

  const renderSidebar = () => {
    return (
      <div className="">
        {/* PRICE */}
        

        {/* FORM */}
        <form method="get" className="mt-15"  onSubmit={submitHandler}>
          <div className="flex gallery-list justify-end m-0 mr-2.5 p-0 w-full">
          <StayDatesRangeInput
            wrapClassName="divide-x divide-neutral-200 dark:divide-neutral-700"
            onChange={(date) => setSelectedDate(date)}
            numberOfMonths={2}
            fieldClassName="p-2"
            defaultValue={selectedDate}
            anchorDirection={windowSize.width > 1400 ? "left" : "right"}
            guetyListingId={guestyListingId}
            defaultFocus={null}
            availableDates={availableDates}
            highlightedDates={checkoutOnlyDates}
            
            
          /> 
          <GuestsInput
            fieldClassName="p-2"
            defaultValue={{
              guestAdults: adults,
              guestChildren: children,
              guestInfants: infants,
            }}
            onChange={data => {
              setAdults(data.guestAdults ?? 0);
              setChildren(data.guestChildren ?? 0);
              setInfants(typeof data.guestInfants === 'undefined' ? 0 : data.guestInfants);
            }}
            MaxGuests={maxguest}
            MaxAdults={maxAdults}
            MaxChilds={maxChilds}
          />
            
        </div>
        <div className="w-15-btn">
        {DefaultUserID ?
        <ButtonPrimary type="submit">Add to cart</ButtonPrimary>
        :
        <ButtonPrimary onClick={OpenModalLoginSignUp} type="button">Add to cart</ButtonPrimary>
        }
        
        </div>
        </form>
        {/*the message div will be here*/}
        {calendarMessage && 
        <div className="alert alert-danger" role="alert">  <span className="las la-exclamation-circle"></span>{calendarMessage}</div>
        }
        {successMsg && 
          <div className="alert alert-success" role="success alert"><span className="las la-check-circle"></span>
          {successMsg}
          </div>}
          <div className="flex flex-col space-y-4 payment-detail-part">
          <div dangerouslySetInnerHTML={ {__html: priceBreakDown} } />
          </div>
      </div>
    );
  };

  return (
    <div
      className={`nc-ListingExperiencesDetailPage  ${className}`}
      data-nc-id="ListingExperiencesDetailPage"
    >
      {/* SINGLE HEADER */}
      <Helmet title={metatitle} meta={metadata}>
      </Helmet>
      <>
      <header className="w-100">

<div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-1 sm-full-1-img">
  <div
    className="col-span-2 row-span-3 sm:row-span-2 relative  overflow-hidden cursor-pointer"
    onClick={() => handleOpenModal(0)}
  >
    <NcImage
      containerClassName="absolute inset-0"
      className="object-cover w-full h-full"
      src={PHOTOS[0]}
      prevImageHorizontal
    />
    <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
  </div>
  {PHOTOS.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
    <div
      key={index}
      className={`relative  overflow-hidden ${index >= 3 ? "hidden sm:block" : ""
        }`}
    >
      <NcImage
        containerClassName="aspect-w-4 aspect-h-6 sm:aspect-w-6 sm:aspect-h-4"
        className="object-cover w-full h-full"
        src={item || ""}
        prevImageHorizontal
      />

      {/* OVERLAY */}
      <div
        className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
        onClick={() => handleOpenModal(index + 1)}
      />
    </div>
  ))}
  <div className="absolute hidden md:flex md:items-center md:justify-center right-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10" onClick={() => handleOpenModal(0)}>
    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
    </svg>
    <span className="ml-2 text-neutral-800 text-sm font-medium">
      VIEW PHOTOS
    </span>
  </div>
</div>
</header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={PHOTOS}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
        />
      </>

      {/* MAIn */}
      <main className="container mt-11 flex ">
        {/* CONTENT */}
        <div className="w-full  space-y-8 lg:pr-10 lg:space-y-10">
        
          {renderSection1()}
          {/* {renderSection2()} */}
          {/*renderSection3()*/}
          {/*renderSectionCheckIndate()*/}
          <div className="border-b mb mb-10 mt-10"></div>
          {renderSection6()}
          <div className="border-b mb mb-10 mt-10"></div>
          {renderSection7()} 
          <div className="border-b mb mb-10 mt-10"></div>
          {renderSection5()}
          <div className="border-b mb mb-10 mt-10"></div>
          {renderSection9()}
         
          
          {/* <div className="fixd_bottem_section">
     <div className="container">
    <div className="row_fixd">
        <div className="img_left_flex">
        <NcImage src={listingFeaturedImg} /></div>
         <div className="title_left_flex">
             <h4>{listingName}</h4>
             <div className="DesktopBookingBar">
                 <span className="ret_fixd">
                                          <sup>{currency_code}</sup>{listingPrice}/night                                      </span>
                 <span className="bg_icon">
             <svg viewBox="0 0 18 30" xmlns="http://www.w3.org/2000/svg">
                 <path d="M17.778 10.556h-8.89l5-10.556H3.334L0 15h7.222L.556 30z" fill="currentColor" fillRule="nonzero"></path></svg>
            Instant Book</span>
            </div>
            
         </div>
          <div className="icon_btn_left_flex">
              <a href={listingURL} target={"_blank"}><ButtonPrimary type="submit">Book Now</ButtonPrimary></a>
              <a href="https://giftcards.tinyaway.com/" target={"_blank"} className="gift_fixd_icon ml-5">
                <svg viewBox="0 0 30 27"><g fillRule="nonzero" fill="currentColor"><path d="M3.215 15.002v10.104c0 .93.802 1.684 1.786 1.684h8.93V15.002H3.215zM28.13 6.722h-5.934a7.204 7.204 0 0 0 1.046-.755c1.514-1.37 1.514-3.598 0-4.968-1.47-1.334-4.031-1.33-5.5 0-.814.735-2.97 3.725-2.672 5.723h-.135c.296-1.998-1.86-4.988-2.672-5.723-1.47-1.33-4.032-1.33-5.5 0C5.25 2.37 5.25 4.598 6.76 5.967a7.34 7.34 0 0 0 1.049.755H1.875C.842 6.722 0 7.48 0 8.41v2.533c0 .466.42.845.938.845h12.19V8.41h3.75v3.378h12.19c.517 0 .937-.379.937-.845V8.41c0-.93-.84-1.688-1.875-1.688zm-15.114-.056s-.078.056-.346.056c-1.296 0-3.77-1.213-4.578-1.945-.786-.713-.786-1.875 0-2.587.38-.345.885-.534 1.421-.534.535 0 1.04.19 1.42.534 1.264 1.145 2.52 4.07 2.083 4.476zm4.317.056c-.266 0-.345-.054-.345-.056-.437-.407.82-3.331 2.084-4.476.756-.688 2.078-.691 2.84 0 .789.712.789 1.874 0 2.587-.807.732-3.28 1.945-4.579 1.945zM16.074 15.002V26.79h8.93c.986 0 1.786-.754 1.786-1.684V15.002H16.074z"></path></g></svg>
              </a>
          </div>
    </div> 
    </div>
</div> */}
        </div>
        
        {/* SIDEBAR */}
        {/*<div className="hidden lg:block flex-grow">
          <div className="sticky top-24">{renderSidebar()}</div>
        </div>*/}
      </main>
      {renderAddToCart()}
      {/* STICKY FOOTER MOBILE */}
        {/* <div className="block lg:hidden fixed bottom-0 inset-x-0 py-4 bg-white text-neutral-900 border-t border-neutral-200 z-20">
          <div className="container flex items-center justify-between">
            <span className="text-2xl font-semibold">
              $311
              <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
                /person
              </span>
            </span>

            <ButtonPrimary href="##">Reserve</ButtonPrimary>
          </div>
        </div> */}

      {/* OTHER SECTION */}
      {/* <div className="container py-24 lg:py-32"> */}
        {/* SECTION 1 */}
        {/*<div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            heading="Explore by types of stays"
            subHeading="Explore houses based on 10 types of stays"
            categoryCardType="card5"
            itemPerRow={5}
            sliderStyle="style2"
          />
        </div> */}

        {/* SECTION */}
        {/*<SectionSubscribe2 className="pt-24 lg:pt-32" />*/}
      {/* </div> */}
    </div>
  );
};

export default ListingExperiencesDetailPage;
