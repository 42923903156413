import React, { FC, Fragment,ReactNode,useEffect,useState,useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import ButtonClose from "shared/ButtonClose/ButtonClose";
export interface ShareBtnsProps {
  className?: string;
  DefaultUserID?: number;
  defaultValue?:boolean;
  onChange?:() => void;
}
const ShareBtns: FC<ShareBtnsProps> = ({
  className = "",
  DefaultUserID = localStorage.getItem("user_id") ? localStorage.getItem("user_id") : 0,
  onChange
}) => {

  // const [succeeded, setSucceeded]                               = useState(false);
  // useEffect(() => {
  //   if (onChange) {
  //     onChange(succeeded);
  //   }
  // }, [succeeded]);

  const handleChange = async (event:any) => onChange && onChange();
 
  const LikeSaveBtns = () => {
    return (
      <div className="flow-root">
        <div className="flex text-neutral-700 dark:text-neutral-300 text-sm -mx-3 -my-1.5">
          <span className="d-flex items-center py-1.5 px-3 flex rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-800 cursor-pointer" onClick={handleChange}>
            <i className="las la-share-alt-square  text-2xl"></i>
            <span className="hidden sm:block ml-2.5 uppercase">Share</span>
          </span>
          
        </div>
      </div>
    );
  };
  return (
    <>
      {LikeSaveBtns()}
     
    </>
  );
};
export default ShareBtns;





