import React, { FC,useState,useRef,useEffect } from "react";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionHeroArchivePage from "components/SectionHeroArchivePage/SectionHeroArchivePage";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionGridHasMap from "./SectionGridHasMap";
import { useParams,useLocation  } from 'react-router-dom';
import { Helmet } from "react-helmet";
import * as framerMotion from "framer-motion";
import TabFilters from "./TabFilters";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionGridFilterCard from "./SectionGridFilterCard";
import axios from 'axios'; 
const { motion } = framerMotion;

export interface ListingStayPageProps {
  className?: string;
}
interface FiltersState {
  experienceType: string[];
  amenities: string[];
  rooms: { beds: number; bedrooms: number; bathrooms: number };
}

const ListingStayPage: FC<ListingStayPageProps> = ({
  className = "",
}) => {
  const { pathname } = useLocation();
  const params = useParams<{ state?: string; country?: string }>();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const togglePopup = () => setIsOpen(!isOpen);
  const closePopup = () => setIsOpen(false);
  const [viewMode, setViewMode] = useState<"list" | "map">("list"); // ✅ Toggle List / Map
  const [filters, setFilters] = useState<FiltersState>({
    experienceType: [],
    amenities: [],
    rooms: { beds: 0, bedrooms: 0, bathrooms: 0 },
  });
  const [listings, setListings] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const currentpageno = Number(new URLSearchParams(window.location.search).get("page")) || 1; 
  const [pageTitle, setPageTitle] = useState("");
  const [pageDescription, setPageDescription] = useState("");
  const [paginationSlug, setPaginationSlug] = useState("");
  const [metaTitle, setMetaTitle] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pageNumber = Number(searchParams.get("page")) || 1;
  
  const extractLocationFromURL = () => {
    const pathParts = location.pathname.split("/").filter(Boolean); // Remove empty segments
    let extractedState = "";
    let extractedCountry = "";
    let extractedArea = "";
    let extractedCity = "";
  
    if (pathParts.includes("state")) {
      extractedState = pathParts[pathParts.indexOf("state") + 1] || "";
    }
    if (pathParts.includes("country")) {
      extractedCountry = pathParts[pathParts.indexOf("country") + 1] || "";
    }
    if (pathParts.includes("area")) {
      extractedArea = pathParts[pathParts.indexOf("area") + 1] || "";
    }
    if (pathParts.includes("city")) {
      extractedCity = pathParts[pathParts.indexOf("city") + 1] || "";
    }
  
    return { extractedState, extractedCountry, extractedArea, extractedCity };
  };
  
  useEffect(() => {
    setIsLoading(false);
    const { extractedState, extractedCountry, extractedArea, extractedCity } = extractLocationFromURL();
  
    // ✅ Create new URLSearchParams
    let filterParams = new URLSearchParams(location.search);
  
    // ✅ Remove unnecessary parameters to avoid duplication
    const paramsToDelete = ["experienceType[]", "amenities[]", "beds", "bedrooms", "bathrooms"];
    paramsToDelete.forEach((param) => filterParams.delete(param));
  
    // ✅ Append filters
    filters.experienceType.forEach((type) => {
      filterParams.append("experienceType[]", type);
    });
  
    filters.amenities.forEach((amenity) => {
      filterParams.append("amenities[]", amenity);
    });
  
    if (filters.rooms.beds) filterParams.set("beds", filters.rooms.beds.toString());
    if (filters.rooms.bedrooms) filterParams.set("bedrooms", filters.rooms.bedrooms.toString());
    if (filters.rooms.bathrooms) filterParams.set("bathrooms", filters.rooms.bathrooms.toString());
  
    // ✅ Ensure location parameters exist in API but NOT duplicated in URL
    let apiParams = new URLSearchParams(filterParams);
    if (extractedState) apiParams.set("state", extractedState);
    if (extractedCountry) apiParams.set("country", extractedCountry);
    if (extractedArea) apiParams.set("area", extractedArea);
    if (extractedCity) apiParams.set("city", extractedCity);
  
    if (location.pathname.includes(`state/${extractedState}`)) filterParams.delete("state");
    if (location.pathname.includes(`country/${extractedCountry}`)) filterParams.delete("country");
    if (location.pathname.includes(`area/${extractedArea}`)) filterParams.delete("area");
    if (location.pathname.includes(`city/${extractedCity}`)) filterParams.delete("city");
  
    // ✅ Add pagination and view mode to BOTH API and URL
    filterParams.set("page", pageNumber.toString());
    filterParams.set("view", viewMode);
    apiParams.set("page", pageNumber.toString());
    apiParams.set("view", viewMode);
  
    // ✅ Update URL without duplication
    const newQueryString = filterParams.toString();
    const newUrl = newQueryString ? `${location.pathname}?${newQueryString}` : location.pathname;
  
    if (window.location.search !== `?${newQueryString}`) {
      window.history.replaceState(null, "", newUrl);
    }
  
    // ✅ Correct API request with all required parameters
    axios.get(`${process.env.REACT_APP_BASE_URL}GetAllListing?${apiParams.toString()}`)
      .then((res) => {
        setListings(res.data.listings);
        setTotalPages(res.data.total_pages);
        setTotalRecords(res.data.total_record);
        setPageTitle(res.data.page_title);
        setPageDescription(res.data.page_description);
        setPaginationSlug(res.data.pagination_slug);
        setMetaTitle(res.data.meta_title);
        setMetaDescription(res.data.meta_description);
        setIsLoading(true);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      });

  }, [filters, pageNumber, viewMode]);

  const handleFiltersChange = (updatedFilters: FiltersState) => {
    setFilters(updatedFilters);
    console.log("Updated Filters:", updatedFilters);
  };
  return (
    <div
      className={`nc-ListingStayMapPage relative bg-color-gry ${className}`}
      data-nc-id="ListingStayMapPage"
    >
      
      <Helmet>
        <title>Tiny Houses Mapview - Tiny Away | Weekend Getaway | Rent Tiny House</title>
      </Helmet>
      <BgGlassmorphism />
      {/* SECTION HERO */}
      <div className="container relative container-serch-form-full ">
      <div className="flex justify-start pt-12">
        <div className="play-stay-icon">
          <button
              // onClick={() => setIsOpen(!isOpen)}
              className="play-stay-icon"
            >
              <span className="btn-con"><img src="https://pictures.tinyaway.com/filters:format(svg)/200x200/icons/TA_UIUX_Destination1A.svg" /></span>
              <span>PLACES TO STAY</span>
            </button>
            {isOpen && (
        <>
          {/* Dark Background */}
          <motion.div
            className="fixed inset-0 bg-black bg-opacity-50 z-40"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={closePopup}
          />

          {/* Pop-up Content */}
          <motion.div
            className="absolute top-50 left-10 bg-white shadow-lg rounded-lg p-2 z-50 max-width-200"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.8 }}
          >
            {/* <button onClick={closePopup} className="absolute top-2 right-2 text-gray-600">
              ✖
            </button> */}
            <div className="flex  space-y-2 rounded-model-ui">
              <button className="hover:bg-gray-100 p-2 rounded">
                <span className="icon-text-popup color-theme"><img src="https://pictures.tinyaway.com/filters:format(svg)/200x200/icons/TA_UIUX_Destination1A.svg" /></span>
                <span>Places to Stay</span>
              </button>
              <button className="hover:bg-gray-100 p-2 rounded">
              <span className="icon-text-popup color-gry"><img src="https://pictures.tinyaway.com/filters:format(svg)/200x200/icons/TA_UIUX_Destination2B.svg" /></span>
                <span>Things to Do</span>
                </button>
            </div>
          </motion.div>
        </>
      )}
        </div>
        <div className="m-0 w-5/6 search-left-part">
          <SectionHeroArchivePage currentPage="Stay" currentTab="Stay" /> </div>
          <TabFilters onFiltersChange={handleFiltersChange} />
            <div className="icon-set-filtter">
              <ButtonPrimary className={`icon-ser-fileyet ${viewMode === "map" ? "class-active-fillter" : ""}`} 
        onClick={() => setViewMode("map")}>
                <i className="text-lg las la la-filter">
                <svg width="37" height="50" viewBox="0 0 37 50" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M18.4804 7.58252C12.4711 7.58252 7.58203 12.4714 7.58203 18.4807C7.58203 24.4899 12.4711 29.3788 18.4804 29.3788C24.4897 29.3788 29.3787 24.4899 29.3787 18.4807C29.3787 12.4714 24.4897 7.58252 18.4804 7.58252ZM18.4804 26.4473C14.0875 26.4473 10.5134 22.8734 10.5134 18.4805C10.5134 14.0876 14.0875 10.5139 18.4804 10.5139C22.8733 10.5139 26.4474 14.0878 26.4474 18.4807C26.4474 22.8736 22.8735 26.4473 18.4804 26.4473Z" fill="#000002"/>
              <path d="M18.4804 0C8.29024 0 0 8.29024 0 18.4804C0 24.3572 4.92857 33.1127 7.86637 37.8085C9.7993 40.898 11.8765 43.8383 13.715 46.0876C16.6661 49.6979 17.7223 50 18.4806 50C19.2502 50 20.2474 49.6977 23.1893 46.0855C25.032 43.8227 27.1101 40.8841 29.0411 37.8109C31.999 33.1035 36.961 24.3323 36.961 18.4802C36.961 8.29024 28.6707 0 18.4804 0ZM27.0089 35.5285C23.2184 41.681 19.7385 45.8385 18.463 46.8998C17.1808 45.8711 13.7574 41.8125 9.91401 35.5479C5.47654 28.3143 2.93154 22.0934 2.93154 18.4804C2.93135 9.90659 9.90659 2.93135 18.4804 2.93135C27.0542 2.93135 34.0294 9.90659 34.0294 18.4804C34.0296 22.0727 31.4706 28.2863 27.0089 35.5285Z" fill="#000002"/>
              </svg>
                </i>
              </ButtonPrimary>
              <ButtonPrimary   className={`icon-ser-fileyet ${viewMode === "list" ? "class-active-fillter" : ""}`} 
        onClick={() => setViewMode("list")}>
              <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M17.8571 0H3.57143C2.62423 0 1.71582 0.376274 1.04605 1.04605C0.376274 1.71582 0 2.62423 0 3.57143V17.8571C0 18.8043 0.376274 19.7128 1.04605 20.3825C1.71582 21.0523 2.62423 21.4286 3.57143 21.4286H17.8571C18.8043 21.4286 19.7128 21.0523 20.3825 20.3825C21.0523 19.7128 21.4286 18.8043 21.4286 17.8571V3.57143C21.4286 2.62423 21.0523 1.71582 20.3825 1.04605C19.7128 0.376274 18.8043 0 17.8571 0ZM3.57143 17.8571V3.57143H17.8571V17.8571H3.57143Z" fill="black"/>
                <path d="M46.4284 0H32.1427C31.1955 0 30.2871 0.376274 29.6173 1.04605C28.9476 1.71582 28.5713 2.62423 28.5713 3.57143V17.8571C28.5713 18.8043 28.9476 19.7128 29.6173 20.3825C30.2871 21.0523 31.1955 21.4286 32.1427 21.4286H46.4284C47.3756 21.4286 48.284 21.0523 48.9538 20.3825C49.6236 19.7128 49.9999 18.8043 49.9999 17.8571V3.57143C49.9999 2.62423 49.6236 1.71582 48.9538 1.04605C48.284 0.376274 47.3756 0 46.4284 0ZM32.1427 17.8571V3.57143H46.4284V17.8571H32.1427Z" fill="black"/>
                <path d="M17.8571 28.5715H3.57143C2.62423 28.5715 1.71582 28.9478 1.04605 29.6176C0.376274 30.2874 0 31.1958 0 32.143V46.4287C0 47.3759 0.376274 48.2843 1.04605 48.9541C1.71582 49.6238 2.62423 50.0001 3.57143 50.0001H17.8571C18.8043 50.0001 19.7128 49.6238 20.3825 48.9541C21.0523 48.2843 21.4286 47.3759 21.4286 46.4287V32.143C21.4286 31.1958 21.0523 30.2874 20.3825 29.6176C19.7128 28.9478 18.8043 28.5715 17.8571 28.5715ZM3.57143 46.4287V32.143H17.8571V46.4287H3.57143Z" fill="black"/>
                <path d="M46.4284 28.5715H32.1427C31.1955 28.5715 30.2871 28.9478 29.6173 29.6176C28.9476 30.2874 28.5713 31.1958 28.5713 32.143V46.4287C28.5713 47.3759 28.9476 48.2843 29.6173 48.9541C30.2871 49.6238 31.1955 50.0001 32.1427 50.0001H46.4284C47.3756 50.0001 48.284 49.6238 48.9538 48.9541C49.6236 48.2843 49.9999 47.3759 49.9999 46.4287V32.143C49.9999 31.1958 49.6236 30.2874 48.9538 29.6176C48.284 28.9478 47.3756 28.5715 46.4284 28.5715ZM32.1427 46.4287V32.143H46.4284V46.4287H32.1427Z" fill="black"/>
                </svg>
          </ButtonPrimary>
          </div>
        </div>  
      </div>
      {/* SECTION */}
      <div className="container pb-24 lg:pb-32 2xl:pl-10 xl:pr-0 xl:max-w-none container-map-stiky">
      {viewMode === "map" ? (
      <SectionGridHasMap 
      listings={listings}
      totalPages={totalPages}
      totalRecords={totalRecords}
      currentpageno={currentpageno}
      pageTitle={pageTitle}
      pageDescription={pageDescription}
      paginationSlug={paginationSlug}
      metaTitle={metaTitle}
      metaDescription={metaDescription}
      isLoading={isLoading}
      className=""
      />
    ) : (
        <SectionGridFilterCard 
          listings={listings}
          totalPages={totalPages}
          totalRecords={totalRecords}
          currentpageno={currentpageno}
          pageTitle={pageTitle}
          pageDescription={pageDescription}
          paginationSlug={paginationSlug}
          metaTitle={metaTitle}
          metaDescription={metaDescription}
          isLoading={isLoading}
          className="pb-24 lg:pb-32" />
      )}
      </div>
      <div className="container overflow-hidden">
       
      </div>
    </div>
  );
};
export default ListingStayPage;
