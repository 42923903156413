import React, { FC, useEffect,useState,useRef } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import { CouponDataTaxonomy } from "data/types";
import ncNanoId from "utils/ncNanoId";
import NextPrev from "shared/NextPrev/NextPrev";
export interface SectionCounpnSliderProps {
    className?: string;
    itemClassName?: string;
    categories?: CouponDataTaxonomy[];
    appliedCoupon?: string | null;
    onApplyCoupon?: (couponCode: string, type: "PROMO CODE" | "GIFT CODE") => void; // ✅ Updated type
    appliedCouponError?: string;
    onRemoveCoupon?: () => void;
  }
const DEMO_CATS: CouponDataTaxonomy[] = [];

const SectionCounpnSlider: FC<SectionCounpnSliderProps> = ({
    className = "",
    itemClassName = "",
    categories = [],
    appliedCoupon = null,
    onApplyCoupon,
    appliedCouponError,
    onRemoveCoupon
}) => {
    const UNIQUE_CLASS = "glide_" + ncNanoId();
    const glideRef = useRef<any>(null);
  const [sortedCategories, setSortedCategories] = useState(categories);

  useEffect(() => {
    if (appliedCoupon) {
      const appliedIndex = categories.findIndex((item) => item.couponCode === appliedCoupon);
      if (appliedIndex !== -1) {
        const updatedCategories = [...categories];
        const [selectedCoupon] = updatedCategories.splice(appliedIndex, 1);
        updatedCategories.unshift(selectedCoupon);
        setSortedCategories(updatedCategories);
      }
    } else {
      setSortedCategories(categories);
    }
  }, [appliedCoupon, categories]);
    useEffect(() => {
        if (document.querySelector(`.${UNIQUE_CLASS}`)) {
            if (glideRef.current) {
                glideRef.current.destroy(); // Destroy previous instance
              }
            new Glide(`.${UNIQUE_CLASS}`, {
                perView: 1,
                gap: 32,
                bound: true,
                breakpoints: {
                    1280: {
                        perView: 1 - 1,
                    },
                    1024: {
                        gap: 20,
                        perView: 1 - 1,
                    },
                    768: {
                        gap: 20,
                        perView: 1 - 2,
                    },
                    640: {
                        gap: 20,
                        perView: 1 - 3,
                    },
                    500: {
                        gap: 20,
                        perView: 1.3,
                    },
                },
            }).mount();
            return () => {
                if (glideRef.current) {
                  glideRef.current.destroy();
                }
              };
        }
    }, [UNIQUE_CLASS, categories,sortedCategories]);
    return (
        <div className={`nc-SectionSliderNewCategories ${className}`}>
            <div className={`${UNIQUE_CLASS} flow-root`}>
                <div className="glide__track" data-glide-el="track">
                    <ul className="glide__slides">
                        {sortedCategories.map((item, index) => (
                            <li key={index} className={`glide__slide ${itemClassName}`}>
                                {/* {renderCard(item, index)} */}
                                <div className="white-color-bg">
                                    <div className="flex-warp-end-left">
                                        <h4>{item.type} - {item.couponCode}</h4>
                                        <div className="flex gap-2">
                                          <input
                                                id="same-drop-off"
                                                name="drop-off-type"
                                                type="radio"
                                                value={item.couponCode}
                                                className="focus:ring-primary-500 h-4 w-4 text-primary-500 border-neutral-300"
                                                checked={appliedCoupon === item.couponCode}
                                                onChange={() => onApplyCoupon?.(item.couponCode, item.type)} // ✅ Ensure both coupon code & type are passed
                                            />
                                            {appliedCoupon!=null && appliedCoupon === item.couponCode  && 
                                                <span className="close-promo-code" onClick={onRemoveCoupon}><i className="las la-times  mr-2"></i></span>
                                            }
                                        </div>
                                    </div>
                                    <p className="mb-0-p">{item.heading} <br>
                                    </br>{item.couponDesc}</p>
                                    <div className="left-right-boder-box"><div className="hr"></div></div>
                                    {appliedCouponError!='' && appliedCoupon === item.couponCode && 
                                    <div className="error-rh">
                                        This promo code cannot be used for the current payment amount. The minimum spend needed to avail of this promo code will not be applied. Please double check the total amount to be paid before booking.
                                    </div>}
                                    {appliedCoupon===null  && 
                                    <div className="flex-wrop-textend-left">
                                        <div className="sm-text-lg">
                                            Expires {item.expiry_date}
                                        </div>
                                        <i className="text-lg las la-info-circle pt-3"></i>
                                    </div>
                                    }
                                    {appliedCoupon!=null && appliedCoupon === item.couponCode ?
                                    <div className="color-sm-prom">Applied</div>
                                    :
                                    <div className="color-sm-prom">Non-applicable Activity List</div>
                                    }
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                {categories.length > 1 && <NextPrev className="justify-center mt-5  " />}
            </div>
        </div>
    );
};
export default SectionCounpnSlider;
