import { CustomLink } from "data/types";
import { relativeTimeRounding } from "moment";
import React, { FC } from "react";
import { Link } from "react-router-dom";
import twFocusClass from "utils/twFocusClass";

export interface PaginationProps {
  className?: string;
  count?:number;
  pageno?:string;
  page_url?:string;
  queryString?:boolean;
}

const Pagination: FC<PaginationProps> = ({ className = "", count = 0, pageno = "", page_url, queryString = false }) => {
  let currentPage = parseInt(pageno) || 1;
  let totalPages = count;
  let paginationArray: CustomLink[] = [];

  const createPageLink = (page: number) => {
    const searchParams = new URLSearchParams(window.location.search);
  
    // ✅ Remove the existing "page" parameter to prevent duplication
    searchParams.delete("page");
  
    // ✅ Append the correct "page" parameter
    searchParams.append("page", page.toString());
  
    return `/${page_url}?${searchParams.toString()}`;
  };

  if (totalPages <= 7) {
    // Show all pages if total pages are 7 or less
    paginationArray = Array.from({ length: totalPages }, (_, i) => ({
      label: `${i + 1}`,
      href: createPageLink(i + 1),
    }));
  } else {
    // Always show first two and last two pages
    paginationArray.push({ label: "1", href: createPageLink(1) });
    paginationArray.push({ label: "2", href: createPageLink(2) });

    if (currentPage > 4) paginationArray.push({ label: "...", href: "#" });

    // Show current page ±1 (for mid-range pages)
    let start = Math.max(3, currentPage - 1);
    let end = Math.min(totalPages - 2, currentPage + 1);
    
    for (let i = start; i <= end; i++) {
      paginationArray.push({ label: `${i}`, href: createPageLink(i) });
    }

    if (currentPage < totalPages - 3) paginationArray.push({ label: "...", href: "#" });

    paginationArray.push({ label: `${totalPages - 1}`, href: createPageLink(totalPages - 1) });
    paginationArray.push({ label: `${totalPages}`, href: createPageLink(totalPages) });
  }

  return (
    <nav className={`nc-Pagination inline-flex space-x-1 text-base font-medium ${className}`}>
      {paginationArray.map((pag, index) =>
        pag.label === "..." ? (
          <span key={index} className="inline-flex w-11 h-11 items-center justify-center">...</span>
        ) : (
          <Link
            key={index}
            className={`inline-flex w-11 h-11 items-center justify-center rounded-full ${
              parseInt(pag.label) === currentPage ? "bg-primary-6000 text-white" : "bg-white hover:bg-neutral-100 border border-neutral-200"
            }`}
            to={pag.href}
          >
            {pag.label}
          </Link>
        )
      )}
    </nav>
  );
};

export default Pagination;
