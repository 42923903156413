import Label from "components/Label/Label";
import React, { FC, Fragment, useState, useEffect, useRef } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'
export interface PageReferralProgramProps {
  className?: string;
  DefaultUserID?: string;
}

const PageReferralProgram: FC<PageReferralProgramProps> = ({
  className = "",
  DefaultUserID = localStorage.getItem("user_id") ? localStorage.getItem("user_id") : "",
}) => {
  const options = {
    title: {
      text: 'Total Gift Card Value USD$500',
      align: 'left'
    },
    series: [{
      type: 'pie',
      data: [{
        y: 1,
        name: "Used Gift Value",
        color: "#000000"
      }, {
        y: 7,
        name: "Balance Gift Value",
        color: "#f15e75"
      }]
    }]
  }


  const PagePeferralProgram = () => {
    return (
      <div className="right-user-details-card">
        <div className="card">
          <div className="flex justify-between">
            <h4>Referral Program</h4>

          </div>

          <div className="flex">
            <div className="card-earn">
              <h5>INVITE FRIENDS AND GET S$20! EARN UP TO S$200 IN TRIP COINS!</h5>
              <div>
              
              <img src="https://pictures.tinyaway.com/filters:format(svg)/icons/TA_UIUX_Referral1.svg" />
               

                <p>WHEN FRIENDS REGISTER VIA YOUR LINK, THEY CAN GET S$20 WORTH OF EXCLUSIVE PROMO CODES FOR TINYWAY.</p>
                <div className="text-center">
                  <a href="" className="btn btn-box-css big-btn">INVITE FRIENDS</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-5">
          <div className="flex flex-direction-column text-center w-100 mb-6">
            <h4 className="sub-title text-center">YOUR INVITATIONS</h4></div>

              
            <div className="vefs-milestone-wrapper mt-5">
            <div className="milestone-container">
            <div className="label-container label-container-sm-label">
                <div className="milestones milestone__3">
                  <div className="label colored">1 friend</div>
                </div>
                <div className="milestones milestone__15">
                  <div className="label colored">2 friend</div>
                </div>
                <div className="milestones milestone__30">
                  <div className="label colored">3 friend</div>
                </div>
                <div className="milestones milestone__45">
                  <div className="label">4 friend</div>
                </div>
                <div className="milestones milestone__60">
                  <div className="label">5 friend</div>
                </div>
                <div className="milestones milestone__75">
                  <div className="label">6 friend</div>
                </div>
                <div className="milestones milestone__85">
                  <div className="label">7 friend</div>
                </div>
                <div className="milestones milestone__95">
                  <div className="label">8 friend</div>
                </div>
                
              </div>
              
              <div className="chart-container">
                <div className="line-container">
                  <div className="line"></div>
                  <div className="line left" style={{width: "15%"}}></div>
                </div>
                <div className="dot-container">
                  <div className="milestones milestone__3">
                    <div className="dot completed colored"></div>
                  </div>
                  <div className="milestones milestone__15">
                    <div className="dot completed colored"></div>
                  </div>
                  <div className="milestones milestone__30">
                    <div className="dot completed colored"></div>
                  </div>
                  <div className="milestones milestone__45">
                    <div className="dot"></div>
                  </div>
                  <div className="milestones milestone__60">
                    <div className="dot"></div>
                  </div>
                  <div className="milestones milestone__75">
                    <div className="dot"></div>
                  </div>
                  <div className="milestones milestone__85">
                    <div className="dot"></div>
                  </div>
                  <div className="milestones milestone__95">
                    <div className="dot"></div>
                  </div>

                </div>
              </div>
              <div className="label-container">
                <div className="milestones milestone__3">
                  <div className="label colored">100 points</div>
                </div>
                <div className="milestones milestone__15">
                  <div className="label colored">200 points</div>
                </div>
                <div className="milestones milestone__30">
                  <div className="label colored">300 points</div>
                </div>
                <div className="milestones milestone__45">
                  <div className="label">400 points</div>
                </div>
                <div className="milestones milestone__60">
                  <div className="label">500 points</div>
                </div>
                <div className="milestones milestone__75">
                  <div className="label">600 points</div>
                </div>
                <div className="milestones milestone__85">
                  <div className="label">700 points</div>
                </div>
                <div className="milestones milestone__95">
                  <div className="label">800 points</div>
                </div>
                
              </div>
            </div>
          </div>



          <div className="mt-5">
          <div className="flex flex-direction-column text-center w-100">
             
            <div className="row-card">
              
              <div className="col-4">
                <div className="text-card-icon m-2">1</div>
                <h5 className="font-bold text-gray-500">IN PROGRESS</h5>
              </div>

              <div className="col-4">
                <div className="text-card-icon m-2">2</div>
                <h5 className="font-bold text-gray-500">COMPLETED</h5>
              </div>

              <div className="col-4">
                <div className="text-card-icon m-2">2 (200 POINTS)</div>
                <h5 className="font-bold text-gray-500">TOTAL EARNED</h5>
              </div>

            </div>
        </div>


        </div>


        </div>


        <div className="card mt-5">
          <div className="flex flex-direction-column text-center w-100">
            <h4 className="sub-title text-center">HOW IT WORKS</h4></div>
            <div className="row-card">
              
              <div className="col-4">
                <div className="rond-icon">
                <img src="https://pictures.tinyaway.com/filters:format(svg)/200x200/icons/TA_UIUX_Referral2A.svg" />
               
                 </div>
                <div className="text-card-icon">SHARE YOUR REFERRAL LINK</div>
              </div>

              <div className="col-4">
                <div className="rond-icon">
                <img src="https://pictures.tinyaway.com/filters:format(svg)/200x200/icons/TA_UIUX_Referral2B.svg" />
                </div>
                <div className="text-card-icon">FRIEND REGISTERS & COMPLETES FIRST STAY</div>
              </div>

              <div className="col-4">
                <div className="rond-icon">
                  
                <img src="https://pictures.tinyaway.com/filters:format(svg)/200x200/icons/TA_UIUX_Referral2C.svg " />
               
                </div>
                <div className="text-card-icon">GET CREDIT IN YOUR ACCOUNT</div>
              </div>

            </div>
        </div>


      </div>
    );
  };

  return (
    <div className="bgcolor-light pb-14 pt-10">
      <div className="container">

        <div className="row-profile  mt-5 mb-5">

          <CommonLayout />
          {PagePeferralProgram()}

        </div>
      </div>
    </div>
  );
};
export default PageReferralProgram;
