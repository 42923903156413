import React, { FC, useEffect, useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from 'axios';
import { useAuth } from "hooks/useAuth"; // Adjust path if needed
import { StateToString } from "redux-logger";

export interface CheckOutFormProps {
  className?: string;
  clientSecret?: string;
  bookingRef?: string;
  grandTotalAmount?:number;
  appliedDiscountCode?:string | null;  // ✅ Allow null as well;
  appliedGiftCardCode?:string | null;  // ✅ Allow null as well;
  onPaymentSuccess: (paymentData: any) => void; // ✅ Callback to send data to parent,
  cartIds?:string;
  paymentmessage?:String;
  countryID?:string;
}

const CheckOutForm: FC<CheckOutFormProps> = ({
  clientSecret = "",
  bookingRef,
  grandTotalAmount,
  appliedDiscountCode,
  appliedGiftCardCode,
  onPaymentSuccess,
  cartIds,
  paymentmessage,
  countryID
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [processing, setProcessing] = useState(false);
  const [succeeded, setSucceeded] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [disabled, setDisabled] = useState(true);
  const { user } = useAuth();

  const handleChange = (event: any) => {
    setDisabled(event.empty);
    setError(event.error ? event.error.message : "");
  };

  const handleSubmit = async (ev: any) => {
    ev.preventDefault();
    setProcessing(true);

    if (!stripe || !elements) {
      setError("Stripe has not loaded yet.");
      setProcessing(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setError("Payment card details are required.");
      setProcessing(false);
      return;
    }

    try {
      // ✅ Validate Payment Before Processing
      const validationResponse = await axios.post(
        process.env.REACT_APP_BASE_URL + "validate_strip_payment",
        {
          bookingRef,
          user_id: user?.user_id,
          amount:grandTotalAmount,
          discount_code:appliedDiscountCode,
          giftcard_code:appliedGiftCardCode,
          cart_ids:cartIds
        },
        {
          headers: { "Content-Type": "application/json" }
        }
      );
      
      // Axios automatically parses JSON, so we can directly access `data`
      const validationData = validationResponse.data;
      
      if (!validationData.success) {
        setError(validationData.message || "Payment validation failed.");
        setProcessing(false);
        return;
      }

      // ✅ Process Stripe Payment
      const payload = await stripe.confirmCardPayment(clientSecret, {
        payment_method: { card: cardElement }
      });

      if (payload.error) {
        setError(`Payment failed: ${payload.error.message}`);
        setProcessing(false);
      } else if (payload.paymentIntent) {
        const transactionId = payload.paymentIntent.id;
        const amount = payload.paymentIntent.amount / 100; // Convert from cents to dollars
        const currency = payload.paymentIntent.currency;
        const paymentMethod = payload.paymentIntent.payment_method_types[0]; // e.g., "card"
        setError(null);
        setProcessing(false);
        setSucceeded(true);

        // ✅ Send payment details to the parent component
        onPaymentSuccess({
          bookingRef,
          user_id: user?.user_id,
          ppaymentStatus: payload.paymentIntent.status, // e.g., "succeeded"
          transactionId, // ✅ Save Transaction ID
          amount,
          currency,
          paymentMethod,
          cartIds,
          appliedDiscountCode,
          appliedGiftCardCode,
          paymentmessage,
          countryID
        });
      }
    } catch (err) {
      setError("Something went wrong. Please try again.");
      setProcessing(false);
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <div className="space-y-1 mt-2 border-input-all">
        <CardElement id="card-element" onChange={handleChange} />
      </div>

      <div className="mt-4 button-check-out-page">
        <div className="submet-cart-info">
          <div className="ButtonPrimary-btn">
            <ButtonPrimary disabled={processing || disabled || succeeded} id="submit">
              {processing ? <span>Processing...</span> : "COMPLETE BOOKING"}
            </ButtonPrimary>

            {/* ✅ ADD MORE HOUSE Button */}
            <a href="/listings" className="ml-4 text-blue-600 underline">
              ADD MORE HOUSE
            </a>
          </div>
        </div>
      </div>

      {/* Show error or success messages */}
      {error && <div className="alert alert-danger"><span className="las la-exclamation-circle"></span> {error}</div>}
      {succeeded && <div className="alert alert-success"><span className="las la-check-circle"></span> Payment succeeded!</div>}
    </form>
  );
};

export default CheckOutForm;
