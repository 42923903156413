import React, { FC,useEffect,useState } from "react";
import { Link } from "react-router-dom";
import logoImg from "images/logo.png";
import logoLightImg from "images/logo-light.png";
import LogoSvgLight from "./LogoSvgLight";
import LogoSvg from "./LogoSvg";
import LogoSvgGrampians from "./LogoSvgGrampians";
import axios from 'axios';  

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
}
const Logo: React.FC<LogoProps> = ({
  img = logoImg,
  imgLight = logoLightImg,
  className = "",
}) => {
  return (
    <Link
      to="/"
      className={`ttnc-logo inline-block text-primary-6000 ${className}`}
    >
      <LogoSvg />
    </Link>
  );
};

export default Logo;
