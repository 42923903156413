import { createContext, useState, useEffect } from "react";
import { Route, Redirect,useHistory,useLocation} from 'react-router-dom';
import axios from "axios";
interface User {
  user_id: string;
  first_name: string;
  last_name: string;
  display_name: string;
  user_email: string;
  gender:string;
  user_name:string;
  dob:string;
  phone_number:string;
  user_profile_pic: string;
  totalcart:number;
  country_id:string;
  state_id:string;
  country_name:string;
  state_name:string;
  city:string;
  expires_in: number;
  email_verified:boolean;
  mobile_verified:boolean;
  social_google_login:boolean;
  social_facebook_login:boolean;
  user_rewards:{points:number,tier:string,progress:number}
}
interface AuthContextType {
    user: User | null;
    setUser: (user: string | null) => void;
    login: (email: string, password: string) => Promise<{ success: boolean; user?: string; error?: string }>; // ✅ Updated return type
    loginWithGoogle: (response: any) =>  Promise<{ success: boolean; user?: string; error?: string }>;
    loginWithFacebook: (response: any) =>  Promise<{ success: boolean; user?: string; error?: string }>;
    logout: () => void;
    loading: boolean;
    updateUser: (keyOrObject: string | Partial<User>, value?: any) => void; //
  }

// ✅ Export AuthContext so other files can import it
export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
const [user, setUser] = useState<any | null>(() => {
    // ✅ Load user data from localStorage on first render
    const storedUser = localStorage.getItem("user");
    return storedUser ? JSON.parse(storedUser) : null;
  });
  const [loading, setLoading] = useState(true);
  const history                                                 = useHistory();
  const location = useLocation();
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      axios.get(`${process.env.REACT_APP_BASE_URL}verifyTokens?token=${token}`,{
        headers: {
        //   Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        }})
      .then(res => {
        if(res.data.status){
          //alert('sd');
          setUser(res.data.user);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user",JSON.stringify(res.data.user));
        }else{
          logout();
        }
      })
      .catch(() => logout());
    }
    setLoading(false);
  }, [location.pathname]);

  const login = async (email: string, password: string): Promise<{ success: boolean; user?: string; error?: string }> => {
    try {
      const response = await axios.post(process.env.REACT_APP_BASE_URL + "UserLogin", { email, password });
      if (response.data.status) {
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user",JSON.stringify(response.data.user));
        setUser(response.data.user);
        return { success: true, user: response.data.user,error:response.data.response }; // ✅ Return success response
      } else {
        return { success: false, error: response.data.response || "Invalid credentials" }; // ✅ Return error response
      }
    } catch (error) {
      return { success: false, error: "An error occurred while logging in." }; // ✅ Return error response
    }
  };

  const loginWithGoogle = async (googleResponse: any) : Promise<{ success: boolean; user?: string; error?: string }> =>  {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}UserRegistrationbySocial`, {
          name: googleResponse.profileObj.name,
          first_name: googleResponse.profileObj.givenName,
          last_name: googleResponse.profileObj.familyName,
          accessToken: googleResponse.accessToken,
          email: googleResponse.profileObj.email,
          userID: googleResponse.profileObj.googleId,
          picture: googleResponse.profileObj.imageUrl,
          platform: "google",
      });
      if (response.data.status) {
        setUser(response.data.user);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        return { success: true, user: response.data.user,error: response.data.response }; 
      }else{
        return { success: false, error: response.data.response || "Invalid credentials" }; // ✅ Return error response
      }
    } catch (error) {
      return { success: false, error: "An error occurred while logging in." };
    }
  };

  const loginWithFacebook = async (fbResponse: any) : Promise<{ success: boolean; user?: string; error?: string }> =>  {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}UserRegistrationbySocial`, {
        name: fbResponse.name,
        first_name: fbResponse.first_name,
        last_name: fbResponse.last_name,
        accessToken: fbResponse.accessToken,
        email: fbResponse.email,
        userID: fbResponse.userID,
        picture: fbResponse.picture.data.url,
        platform: "facebook",
      });
      if (response.data.status) {
        setUser(response.data.user);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user", JSON.stringify(response.data.user));
        return { success: true, user: response.data.user,error: response.data.response }; // ✅ Return success response
      }else{
        return { success: false, error: response.data.response || "Invalid credentials" }; // ✅ Return error response
      }
    } catch (error) {
      return { success: false, error: "An error occurred while logging in." };
    }
  };
  // const updateUser = (key: string, value: any) => {
  //   if (user) {
  //     const updatedUser = { ...user, [key]: value };
  //     setUser(updatedUser); // Update state
  //     localStorage.setItem("user", JSON.stringify(updatedUser)); // Update localStorage
  //   }
  // };
  const updateUser = (keyOrObject: string | Record<string, any>, value?: any) => {
    if (!user) return;
    const updatedUser =
      typeof keyOrObject === "string"
        ? { ...user, [keyOrObject]: value } // Single update
        : { ...user, ...keyOrObject }; // Multiple updates
    setUser(updatedUser);
    localStorage.setItem("user", JSON.stringify(updatedUser));
  };
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setUser(null);
    history.push("/"); 
  };
  return (
    <AuthContext.Provider value={{ user, setUser, login,loginWithGoogle,loginWithFacebook, logout, loading,updateUser }}>
      {children}
    </AuthContext.Provider>
  );
};
