import React, { FC, ReactNode , useEffect, useState }  from "react";
import { DEMO_EXPERIENCES_LISTINGS } from "data/listings";
import { ExperiencesDataType } from "data/types";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import HeaderFilter from "./HeaderFilter";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import axios from 'axios'; 
import BackgroundSection from "components/BackgroundSection/BackgroundSection";


// OTHER DEMO WILL PASS PROPS
const DEMO_DATA: ExperiencesDataType[] = DEMO_EXPERIENCES_LISTINGS.filter((_, i) => i < 8);

//
export interface SectionGridFeatureStoryProps {
  stayStories?: ExperiencesDataType[];
  gridClass?: string;
  heading?: ReactNode;
  subHeading?: ReactNode;
  headingIsCenter?: boolean;
  tabs?: string[];
}

const SectionGridFeatureStory: FC<SectionGridFeatureStoryProps> = ({
  stayStories = DEMO_DATA,
  gridClass = "",
  heading = "Tiny House Stories",
  subHeading = "Discover destinations, curating experiences. These are stories on our tiny houses to help you find out what to do in each tiny house.",
  headingIsCenter,
  tabs = [],
}) => {
  const [stories, setStories]                     = useState([]); 
  const [isdataLoaded, setIsdataLoaded]           = useState(false);
  const [customheading, setCustomheading]         = useState(heading); 
  useEffect(() => {
    axios.get(process.env.REACT_APP_BASE_URL+'/GetFeaturedStories')
 	 .then(res => {
          setStories(res.data.stories); 
          if(res.data.stories.length > 0){
            setIsdataLoaded(true);
          }
          setCustomheading(res.data.heading);
    });   
  }, []);
  //console.log(stories);
  const renderCard = (stay: ExperiencesDataType) => {
    return <ExperiencesCard key={stay.id} data={stay} />;
  };
  return (
    <>
      {isdataLoaded ? 
    <div className="relative py-16 mb-6">
    <BackgroundSection />
    <div className="nc-ExperiencesCard relative">
      <HeaderFilter
        tabActive={"New York"}
        subHeading={subHeading}
        tabs={tabs}
        heading={customheading}
        onClickTab={() => {}}
      />
     <div
        className={`grid grid-cols-1 gap-3 md:gap-4 sm:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4 ${gridClass}`}
      > 
        {stories && stories.length!=0 ?
        
        stories.map((stay) => renderCard(stay)) 
        
        : ''
        
        }
      </div>
      <div className="flex justify-center mt-10">
          <a href="/tiny-house-stories" className="text-opacity-90
 group px-6  uppercase py-4 border border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 bg-white">EXPLORE ALL OUR STORIES</a>
          </div>
    </div>
    </div>
      :''
      }
    </>
  );
};

export default SectionGridFeatureStory;
