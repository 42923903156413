import Label from "components/Label/Label";
import React, { FC, useState, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import { Helmet } from "react-helmet";
import axios from "axios";
import SectionPromoCodeSlider from "components/SectionPromoCodeSlider/SectionPromoCodeSlider";
import ButtonCircle from "shared/Button/ButtonCircle";
import { useAuth } from "hooks/useAuth"; // Adjust path if needed

export interface PagePromoCodeProps {
  className?: string;
}

const PagePromoCode: FC<PagePromoCodeProps> = ({ className = "" }) => {
  const { user } = useAuth();
  const [promoCode, setPromoCode] = useState("");
  const [error, setError] = useState("");
  const [resultMessage, setResultMessage] = useState("");
  const [activeTab, setActiveTab] = useState<"available" | "notAvailable">("available");
  const [availableCoupons, setAvailableCoupons] = useState([]);
  const [notAvailableCoupons, setNotAvailableCoupons] = useState([]);
  const [loading, setLoading] = useState(true);

  // ✅ Fetch promo codes from API
  const fetchPromoCodes = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}getUserPromoCodes`, {
        params: { user_id: user?.user_id }
      });

      if (response.data.success) {
        setAvailableCoupons(response.data.available_coupons || []);
        setNotAvailableCoupons(response.data.not_available_coupons || []);
      } else {
        setAvailableCoupons([]);
        setNotAvailableCoupons([]);
      }
    } catch (error) {
      console.error("Error fetching promo codes:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPromoCodes();
  }, []);

  // ✅ Handle input change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPromoCode(e.target.value);
    setError(""); // Clear error on change
  };

  // ✅ Apply promo code & refresh slider
  const handleApplyCode = async () => {
    if (!promoCode.trim()) {
      setError("Please enter a promo code.");
      return;
    }
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}redeemPromocode`,
        { promoCode, user_id: user?.user_id }
      );

      if (response.data.success) {
        setResultMessage("Promo code applied successfully!");
        setPromoCode(""); // Clear input
        fetchPromoCodes(); // ✅ Refresh slider
      } else {
        setResultMessage(response.data.message || "Invalid promo code.");
      }
    } catch (error) {
      setResultMessage("Promo code is invalid.");
    }
  };

  return (
    <div className="bgcolor-light pb-14 pt-10">
      <Helmet title={'Tiny Away Available Promo Code'}></Helmet>
      <div className="container">
        <div className="row-profile mt-5 mb-5">
          <CommonLayout />
          {/* Promo Code Section */}
          <div className="right-user-details-card">
            <div className="card">
              <div className="flex justify-between">
                <h4>Promo Code</h4>
              </div>

              {/* ✅ Promo Code Input */}
              <div className="mt-7 relative max-w-sm">
              <Input
                  type="text"
                  placeholder="Enter promo code and redeem"
                  value={promoCode}
                  onChange={handleInputChange}
                />
                <ButtonCircle
                  onClick={handleApplyCode}
                  className="absolute transform top-1/2 -translate-y-1/2 right-1"
                >
                  <i className="las la-arrow-right text-xl"></i>
                </ButtonCircle>
                
              </div>
              {error && <p className="text-red-500 mt-2">{error}</p>}
              {resultMessage && <p className="text-green-500 mt-2">{resultMessage}</p>}

              {/* ✅ Tabs for Available & Not Available */}
              <div className="list-tab-account mt-5">
                <a
                  href="javascript:void(0);"
                  className={`tab ${activeTab === "available" ? "active" : ""}`}
                  onClick={() => setActiveTab("available")}
                >
                  Available
                </a>
                <a
                  href="javascript:void(0);"
                  className={`tab border-l ${activeTab === "notAvailable" ? "active" : ""}`}
                  onClick={() => setActiveTab("notAvailable")}
                >
                  Not Available
                </a>
              </div>

              <div className="line-mid mt-5 mb-5"></div>

              {/* ✅ Promo Code Slider - Dynamically Load Based on Active Tab */}
              {loading ? (
                <p>Loading...</p> // ✅ Show loading state while fetching
              ) : activeTab === "available" ? (
                <SectionPromoCodeSlider categories={availableCoupons} itemPerRow={2} />
              ) : (
                <SectionPromoCodeSlider categories={notAvailableCoupons} itemPerRow={2} />
              )}

              {/* Fallback Content */}
              <div className="mb-10 mid-box-refl mt-100 text-center w-1/2 m-lr">
                <h5>FRESH OUT OF PROMO CODES</h5>
                <p>Got a promo code? Enter it above. Or you can invite friends to TinyAway and earn rewards.</p>
                <div className="text-center mt-5">
                  <a href="#" className="btn btn-box-css big-btn">INVITE FRIENDS</a>
                  <a href="#" className="btn btn-black big-btn m-8">START EXPLORING</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PagePromoCode;
