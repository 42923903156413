import React from "react";
const RewardCard = ({ points = 0, level = 0, progress = 0,tier='Basic' }) => {
  return (
    <div className="mb-10 reward-card">
      <h3>This is your rewards!</h3>
      <span className="reward-points">{points}</span>
      <div className="level">⭐ Lv. {level}</div>
      <div className="progress-bar">
        <div className={`progress-fill`}  style={{ width: `${progress}%` }}></div> {/* Dynamic progress */}
      </div>
      <div className="progress-text">
        <span>{points} / 1499</span>
        <span>{tier}</span>
      </div>
    </div>
  );
};

export default RewardCard;
