import React, { useState } from "react";
import { useAuth } from "hooks/useAuth";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SocialsList from "shared/SocialsList/SocialsList";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { motion, AnimatePresence } from "framer-motion";

export interface NavMobileProps {
  onClickClose?: () => void;
  menuData: any[];
}

const NavMobile: React.FC<NavMobileProps> = ({ onClickClose,menuData }) => {
  const auth = useAuth();
  const [submenuOpen, setSubmenuOpen] = useState<{ [key: string]: boolean }>({});

  // ✅ Toggle function for smooth submenu animations
  const toggleSubMenu = (menuKey: string) => {
    setSubmenuOpen((prev) => ({
      ...prev,
      [menuKey]: !prev[menuKey],
    }));
  };




  return (
    <div className="min-w-470 overflow-y-auto w-full max-w-sm h-screen border-0 py-2 transition transform ring-1 dark:ring-neutral-700 bg-white dark:bg-neutral-900 divide-y-2 divide-neutral-100 dark:divide-neutral-800 ">
      <div className="py-3 px-5 flex justify-end items-center pb-0 border-0">
        {/* <span className="font-semibold text-lg">Menu</span> */}
        <ButtonClose onClick={onClickClose} />
      </div>

      <ul className="flex flex-col py-6 px-5 pt-0 space-y-1  direction-end border-0">
        {/* ✅ Main Menu Items (Kept in Your Order) */}
        <li className="text-neutral-900 dark:text-white">
          <a href="/" className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg">
            HOME
          </a>
        </li>
        
        {/* ✅ Tiny House Destination (Multi-Level) */}
        <li className="text-neutral-900 dark:text-white">
          <button
            className="flex justify-between w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
            onClick={() => toggleSubMenu("tinyHouseDestination")}
          >
            <a href="/book-a-tiny-house" className="">
            TINY HOUSE DESTINATION
            </a>
            <ChevronDownIcon className={`w-5 h-5 transform transition ${submenuOpen["tinyHouseDestination"] ? "rotate-180" : "rotate-0"}`} />
          </button>

          <AnimatePresence>
            {submenuOpen["tinyHouseDestination"] && (
              <motion.ul
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
                className="pl-5 space-y-2 overflow-hidden"
              >
                <li className="text-neutral-900 dark:text-white">
                  <button
                    className="flex justify-between w-full items-center py-2 px-4 text-sm font-medium hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
                    onClick={() => toggleSubMenu("australia")}
                  >
                    <a href="/country/must-visit-in-australia" className="menu-item uppercase">AUSTRALIA</a>
                    <ChevronDownIcon className={`w-5 h-5 transform transition ${submenuOpen["australia"] ? "rotate-180" : "rotate-0"}`} />
                  </button>
                  <AnimatePresence>
                    {submenuOpen["australia"] && (
                      <motion.ul
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                        className="pl-4 space-y-2 overflow-hidden"
                      >
                        <li className="text-neutral-900 dark:text-white">
                          <a
                            href="/state/tiny-houses-in-australia-sydney"
                            className="block py-2 px-4 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg uppercase"
                          >
                            New South Wales
                          </a>
                        </li>
                        <li className="text-neutral-900 dark:text-white">
                          <a
                            href="/state/tiny-houses-in-australia-queensland"
                            className="block py-2 px-4 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg uppercase"
                          >
                            Queensland
                          </a>
                        </li>
                        <li className="text-neutral-900 dark:text-white">
                          <a
                            href="/state/south-australia"
                            className="block py-2 px-4 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg uppercase"
                          >
                            South Australia
                          </a>
                        </li>
                        <li className="text-neutral-900 dark:text-white">
                          <a
                            href="/state/tasmania"
                            className="block py-2 px-4 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg uppercase"
                          >
                            Tasmania
                          </a>
                        </li>
                        <li className="text-neutral-900 dark:text-white">
                          <a
                            href="/state/tiny-houses-in-australia-melbourne"
                            className="block py-2 px-4 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg uppercase"
                          >
                            Victoria
                          </a>
                        </li>
                        <li className="text-neutral-900 dark:text-white">
                          <a
                            href="/state/western-australia"
                            className="block py-2 px-4 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg uppercase"
                          >
                            Western Australia
                          </a>
                        </li>
                      </motion.ul>
                    )}
                  </AnimatePresence>
                </li>
                <li className="text-neutral-900 dark:text-white">
                  <button
                    className="flex justify-between w-full items-center py-2 px-4 text-sm font-medium hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
                    onClick={() => toggleSubMenu("Malaysia")}
                  >
                    <a href="/country/must-visit-in-malaysia" className="menu-item uppercase">Malaysia</a>
                    <ChevronDownIcon className={`w-5 h-5 transform transition ${submenuOpen["Malaysia"] ? "rotate-180" : "rotate-0"}`} />
                  </button>
                  <AnimatePresence>
                    {submenuOpen["Malaysia"] && (
                      <motion.ul
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                        className="pl-4 space-y-2 overflow-hidden"
                      >
                        <li className="text-neutral-900 dark:text-white">
                          <a
                            href="/state/tiny-houses-in-pahang-malaysia"
                            className="block py-2 px-4 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg uppercase"
                          >
                            Pahang
                          </a>
                        </li>
                      </motion.ul>
                    )}
                  </AnimatePresence>
                </li>
                <li className="text-neutral-900 dark:text-white">
                  <button
                    className="flex justify-between w-full items-center py-2 px-4 text-sm font-medium hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
                    onClick={() => toggleSubMenu("Austria")}
                  >
                    <a href="/country/must-visit-in-new-zealand" className="menu-item uppercase">New Zealand</a>
                    <ChevronDownIcon className={`w-5 h-5 transform transition ${submenuOpen["New_Zealand"] ? "rotate-180" : "rotate-0"}`} />
                  </button>
                  <AnimatePresence>
                    {submenuOpen["New_Zealand"] && (
                      <motion.ul
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                        className="pl-4 space-y-2 overflow-hidden"
                      >
                        <li className="text-neutral-900 dark:text-white">
                          <a
                            href="/state/tiny-houses-in-north-island-of-new-zealand"
                            className="block py-2 px-4 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg uppercase"
                          >
                            North Island
                          </a>
                        </li>
                        <li className="text-neutral-900 dark:text-white">
                          <a
                            href="/state/tiny-houses-in-north-south-of-new-zealand"
                            className="block py-2 px-4 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg uppercase"
                          >
                            South Island
                          </a>
                        </li>
                      </motion.ul>
                    )}
                  </AnimatePresence>
                </li>
                <li className="text-neutral-900 dark:text-white">
                  <button
                    className="flex justify-between w-full items-center py-2 px-4 text-sm font-medium hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
                    onClick={() => toggleSubMenu("taiwan")}
                  >
                    <a href="/country/must-visit-in-taiwan" className="menu-item uppercase">Taiwan</a>
                    <ChevronDownIcon className={`w-5 h-5 transform transition ${submenuOpen["taiwan"] ? "rotate-180" : "rotate-0"}`} />
                  </button>
                  <AnimatePresence>
                    {submenuOpen["taiwan"] && (
                      <motion.ul
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                        className="pl-4 space-y-2 overflow-hidden"
                      >
                        <li className="text-neutral-900 dark:text-white">
                          <a
                            href="/state/tiny-houses-in-taiwan-guoxi"
                            className="block py-2 px-4 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg uppercase"
                          >
                            Guoxi
                          </a>
                        </li>
                        <li className="text-neutral-900 dark:text-white">
                          <a
                            href="/state/tiny-houses-in-taiwan-hualien"
                            className="block py-2 px-4 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg uppercase"
                          >
                            Hualien
                          </a>
                        </li>
                        <li className="text-neutral-900 dark:text-white">
                          <a
                            href="/state/tiny-houses-in-taiwan-miaoli"
                            className="block py-2 px-4 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg uppercase"
                          >
                            Kaohsiung City
                          </a>
                        </li>
                        <li className="text-neutral-900 dark:text-white">
                          <a
                            href="/state/tiny-houses-in-taiwan-miaoli"
                            className="block py-2 px-4 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg uppercase"
                          >
                            Miaoli
                          </a>
                        </li>
                        <li className="text-neutral-900 dark:text-white">
                          <a
                            href="/state/tiny-houses-in-taiwan-yilan"
                            className="block py-2 px-4 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg uppercase"
                          >
                            Yilan
                          </a>
                        </li>
                      </motion.ul>
                    )}
                  </AnimatePresence>
                </li>
                <li className="text-neutral-900 dark:text-white">
                  <button
                    className="flex justify-between w-full items-center py-2 px-4 text-sm font-medium hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
                    onClick={() => toggleSubMenu("Europe")}
                  >
                    <a href="javascript:void(0)" className="menu-item uppercase">Europe</a>
                    <ChevronDownIcon className={`w-5 h-5 transform transition ${submenuOpen["Europe"] ? "rotate-180" : "rotate-0"}`} />
                  </button>
                  <AnimatePresence>
                    {submenuOpen["Europe"] && (
                      <motion.ul
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.3, ease: "easeInOut" }}
                        className="pl-4 space-y-2 overflow-hidden"
                      >
                        <li className="text-neutral-900 dark:text-white">
                          <a
                            href="/country/must-visit-in-austria"
                            className="block py-2 px-4 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg uppercase"
                          >
                            Austria
                          </a>
                        </li>
                        <li className="text-neutral-900 dark:text-white">
                          <a
                            href="/country/must-visit-in-denmark"
                            className="block py-2 px-4 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg uppercase"
                          >
                            Denmark
                          </a>
                        </li>
                        <li className="text-neutral-900 dark:text-white">
                          <a
                            href="/country/must-visit-in-france"
                            className="block py-2 px-4 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg uppercase"
                          >
                            France
                          </a>
                        </li>
                        <li className="text-neutral-900 dark:text-white">
                          <a
                            href="/country/must-visit-in-italy"
                            className="block py-2 px-4 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg uppercase"
                          >
                            Italy
                          </a>
                        </li>
                        <li className="text-neutral-900 dark:text-white">
                          <a
                            href="/country/must-visit-in-portugal"
                            className="block py-2 px-4 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg uppercase"
                          >
                            Portugal
                          </a>
                        </li>
                        <li className="text-neutral-900 dark:text-white">
                          <a
                            href="/country/must-visit-in-spain"
                            className="block py-2 px-4 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg uppercase"
                          >
                            Spain
                          </a>
                        </li>
                        <li className="text-neutral-900 dark:text-white">
                          <a
                            href="/country/must-visit-in-sweden"
                            className="block py-2 px-4 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg uppercase"
                          >
                            Sweden
                          </a>
                        </li>
                        <li className="text-neutral-900 dark:text-white">
                          <a
                            href="/country/must-visit-in-united-kingdom"
                            className="block py-2 px-4 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg uppercase"
                          >
                            United Kingdom
                          </a>
                        </li>
                      </motion.ul>
                    )}
                  </AnimatePresence>
                </li>
                {/* <li className="text-neutral-900 dark:text-white">
                  <a href="/tiny-houses-coming-soon" className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg">
                    COMING SOON
                  </a>
                </li> */}
              </motion.ul>
            )}
          </AnimatePresence>
        </li>
        
        {/* ✅ Tiny Away Escape */}
        <li className="text-neutral-900 dark:text-white">
          <button
            className="flex justify-between w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
            onClick={() => toggleSubMenu("tinyAwayEscape")}
          >
            TINY AWAY ESCAPE
            <ChevronDownIcon className={`w-5 h-5 transform transition ${submenuOpen["tinyAwayEscape"] ? "rotate-180" : "rotate-0"}`} />
          </button>
          <AnimatePresence>
            {submenuOpen["tinyAwayEscape"] && (
              <motion.ul
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
                className="pl-5 space-y-2 overflow-hidden"
              >
                {[
                  { label: "GRAMPIANS EDGE @ AUSTRALIA", link: "https://escapeatgrampiansedge.com.au/" },
                  { label: "GRANITE BELT @ AUSTRALIA", link: "https://sommervillevalley.com.au/" },
                  { label: "LAZARUS ISLAND @ SINGAPORE", link: "https://escapeatlazarus.com/" },
                ].map((item) => (
                  <li key={item.label} className="text-neutral-900 dark:text-white">
                    <a href={item.link} target="_blank" className="block py-2 px-4 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg">
                      {item.label}
                    </a>
                  </li>
                ))}
              </motion.ul>
            )}
          </AnimatePresence>
        </li>

        {/* ✅ Tiny Stories */}
        <li className="text-neutral-900 dark:text-white">
          <a href="/tiny-house-stories" className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg">
            TINY STORIES
          </a>
        </li>

        {/* ✅ About Us */}
        <li className="text-neutral-900 dark:text-white">
          <button
            className="flex justify-between w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg"
            onClick={() => toggleSubMenu("aboutUs")}
          >
            ABOUT US
            <ChevronDownIcon className={`w-5 h-5 transform transition ${submenuOpen["aboutUs"] ? "rotate-180" : "rotate-0"}`} />
          </button>
          <AnimatePresence>
            {submenuOpen["aboutUs"] && (
              <motion.ul
                initial={{ height: 0, opacity: 0 }}
                animate={{ height: "auto", opacity: 1 }}
                exit={{ height: 0, opacity: 0 }}
                transition={{ duration: 0.3, ease: "easeInOut" }}
                className="pl-5 space-y-2 overflow-hidden"
              >
                {[
                  { label: "OUR STORY", link: "/about" },
                  { label: "BECOME A HOST", link: "/host-a-tiny-house" },
                  { label: "CONTACT US", link: "/contact-us" },
                  { label: "FAQ & CANCELLATION POLICY", link: "/cancellation-policy" },
                ].map((item) => (
                  <li key={item.label} className="text-neutral-900 dark:text-white">
                    <a href={item.link} className="block py-2 px-4 text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg">
                      {item.label}
                    </a>
                  </li>
                ))}
              </motion.ul>
            )}
          </AnimatePresence>
        </li>
        <li className="text-neutral-900 dark:text-white">
          <a href="/" className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg">
            GIFT CARDS
          </a>
        </li>
        
        <li className="text-neutral-900 dark:text-white">
        {auth.user?.user_id ? 
          <a href="/" className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg">
            LOG OUT
          </a>
        :
        <a href="/login" className="flex w-full items-center py-2.5 px-4 font-medium uppercase tracking-wide text-sm hover:bg-neutral-100 dark:hover:bg-neutral-800 rounded-lg">
            LOGIN
          </a>
        }
        </li>
      </ul>
      <div className="flex flex-col mt-4 px-5 text-neutral-700 dark:text-neutral-300 text-sm">
        <a href="/host-a-tiny-house" className="flex justify-end">
          <ButtonPrimary>Become a host</ButtonPrimary>
        </a>
        <div className="flex justify-end items-center mt-4">
          <SocialsList itemClass="w-9 h-9 flex items-center justify-center rounded-full bg-neutral-100 text-xl dark:bg-neutral-800 dark:text-neutral-300" />
        </div>
      </div>
    </div>
  );
};

export default NavMobile;
