import React, { FC } from "react";

export interface SaleOffBadgeProps {
  className?: string;
  desc?: string;
}

const SaleOffBadge: FC<SaleOffBadgeProps> = ({
  className = "",
  desc = "-10% today",
}) => {
  return (
    <div
      className={`Source-Sans-3  bg-primary-color bg-red-700 flex items-center justify-center left-[140px] nc-SaleOffBadge px-3 py-0.5 rounded-full text-red-50 text-xs top-3`}
      data-nc-id="SaleOffBadge"
    >
      <svg width="20" height="20" className="mr-1" viewBox="0 0 22 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_10_21)">
          <mask id="mask0_10_21" maskUnits="userSpaceOnUse" x="0" y="0" width="22" height="21">
            <path d="M21.6 0H0V20.13H21.6V0Z" fill="white" />
          </mask>
          <g mask="url(#mask0_10_21)">
            <path d="M17.14 15.0695C16.79 11.5695 13.67 9.01953 10.17 9.36953C7.15998 9.66953 4.76998 12.0595 4.46998 15.0695L4.30998 16.6295C4.12998 18.3795 5.41998 19.9495 7.16998 20.1195C7.26998 20.1295 7.37998 20.1395 7.48998 20.1395H14.12C15.88 20.1395 17.31 18.7095 17.31 16.9495C17.31 16.8395 17.31 16.7395 17.29 16.6295L17.13 15.0695H17.14Z" fill="black" />
            <path d="M4.30001 6.37955C3.21001 5.35955 1.51001 5.38955 0.470008 6.45955C-0.339992 7.70955 8.58307e-06 9.37955 1.24001 10.2195C1.81001 10.6895 2.52001 10.9495 3.26001 10.9595C3.96001 10.9795 4.63001 10.6795 5.07001 10.1395C5.88001 8.88955 5.54001 7.21955 4.30001 6.37955Z" fill="black" />
            <path d="M21.1401 6.45955C20.1001 5.38955 18.3901 5.35955 17.3101 6.37955C16.0701 7.20955 15.7301 8.87955 16.5401 10.1395C16.9801 10.6795 17.6501 10.9795 18.3501 10.9595C19.0901 10.9495 19.8001 10.6895 20.3701 10.2195C21.6101 9.38955 21.9501 7.71955 21.1401 6.45955Z" fill="black" />
            <path d="M7.78998 7.35986C7.92998 7.35986 8.06998 7.34986 8.20998 7.31986C9.71998 7.01986 10.61 5.13986 10.21 3.13986C9.80998 1.13986 8.24998 -0.250137 6.74998 0.0598635C5.94998 0.249863 5.28998 0.819864 4.98998 1.57986C4.63998 2.41986 4.54998 3.34986 4.74998 4.23986C5.10998 6.05986 6.42998 7.35986 7.78998 7.35986Z" fill="black" />
            <path d="M13.39 7.30976C13.53 7.33976 13.67 7.34976 13.81 7.34976C15.18 7.34976 16.49 6.03976 16.86 4.22976C17.05 3.33976 16.97 2.40976 16.62 1.56976C16.32 0.799756 15.66 0.229756 14.86 0.0497558C13.35 -0.250244 11.8 1.12976 11.39 3.12976C10.99 5.12976 11.89 7.00976 13.39 7.30976Z" fill="black" />
          </g>
        </g>
        <defs>
          <clipPath id="clip0_10_21">
            <rect width="22" height="21" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <span>{desc}</span>
    </div>
  );
};

export default SaleOffBadge;
