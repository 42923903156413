import React, { FC, ReactNode , useEffect, useState }  from "react";
import { useParams } from 'react-router-dom';
import { DEMO_EXPERIENCES_LISTINGS } from "data/listings";
import { ExperiencesDataType, StayDataType } from "data/types";
import Pagination from "shared/Pagination/Pagination";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import axios from 'axios'; 
import { Helmet } from "react-helmet";

export interface SectionGridFilterCardProps {
  className?: string;
  data?: StayDataType[];
}

const DEMO_DATA: ExperiencesDataType[] = DEMO_EXPERIENCES_LISTINGS.filter((_, i) => i < 8);

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
  data = DEMO_DATA,
}) => {
  const [feature, setFeature]                   = useState([]); 
  const [totalpages, setTotalpages]             = useState(1); 
  const [totalrecord, setTotalrecord]           = useState(1); 
  const [currentpageno, setCurrentpageno]       = useState("1"); 
  const [metatitle, setMetatitle]               = useState(""); 
  const [isdataLoaded, SetIsdataLoaded]         = useState(false);
  let search                                    = window.location.search;
  let params                                    = new URLSearchParams(search);
  let page                                      = params.get('page');
  
  const pageNumber = page || 0;
  //console.log(currentpageno);
  let state             = params.get('state');
  const params_state    = useParams();
  const slug            = Object.values(params_state);
  let country           = params.get('country');
  const params_country  = useParams();
  const slug_country    = Object.values(params_country);

  let filtervars  = '';
  if(slug[0]!=''){
    if(Number(pageNumber) > 0){
      filtervars  = 'state_slug='+slug+'&page='+pageNumber;
    }else{
      filtervars  = 'state_slug='+slug;
    }
    
  }
  useEffect(() => {
  axios.get(process.env.REACT_APP_BASE_URL+'/GetAllStories?'+filtervars)
 	 .then(res => {
         setFeature(res.data.stories); 
         setTotalpages(res.data.total_pages); 
         setTotalrecord(res.data.total_record); 
         setCurrentpageno(pageNumber.toString());
         setMetatitle(res.data.meta_title);
         SetIsdataLoaded(true);
    });   
  },[pageNumber]);


  const renderskeletonLoader = () => {
    return (
      <div className="bg-white border relative rounded-2xl custom-loader w-full  stories-loader">
        <div className="flex p-3">
          <div className="border border-neutral-100 h-14 h-6 rounded-2xl skeleton custom-loader-img-28"></div>
          <div className="pl-4 user-text-loader w-full">
          <div className="border border-neutral-100 group h-4 nc-StayCard relative rounded-2xl skeleton w-1/2"></div>
          <div className="border border-neutral-100 group h-3 nc-StayCard relative rounded-2xl skeleton w-2/3 mt-3"></div>
         
          </div>
        </div>
      <div className="skeleton nc-StayCard group relative border border-neutral-100 rounded-2xl h-1/2 w-full"></div>
      <div className="w-full p-4 space-y-4">
      <div className="border border-neutral-100 group h-4 nc-StayCard relative rounded-2xl skeleton w-1/2"></div>
      <div className="border border-neutral-100 group h-2 nc-StayCard relative rounded-2xl skeleton w-2/3 mb-3"></div>
      
      <div className="border border-neutral-100 group h-2 nc-StayCard relative rounded-2xl skeleton w-2/3 mb-0 mt-2"></div>
      </div>
      <div className="w-full p-4 pt-0 mb-0 mt-3">
          <div className="gap-2 sm:grid grid-cols-3">      
          <div className="border border-neutral-100 h-4 rounded-2xl skeleton"></div>
          <div className="border border-neutral-100 h-4 rounded-2xl skeleton"></div>
          <div className="border border-neutral-100 h-4 rounded-2xl skeleton"></div>
          </div>
        </div>
    </div>
    );
};

  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
       <Helmet title={metatitle}></Helmet>
      <Heading2
        heading=""
        subHeading={
          <span className="dark:text-neutral-400 flex gap-3 items-center mt-3 text-center text-neutral-500">
          <a href="" className="flex gap-2 items-center">
          <span className="sliders-icon"> 
            <i className="las la-sliders-h"></i> </span> {totalrecord} Stories </a>
            {/*<span className="mx-2">·</span>
            Aug 12 - 18
        <span className="mx-2">·</span>2 Guests*/}
          </span>
        }
      />

      <div className="0">
        {/*<TabFilters />*/}
      </div>
      {isdataLoaded ?
      <div className="grid grid-cols-1 gap-6 md:gap-4 sm:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4">
        {feature.map((stay) => (
          <ExperiencesCard key={stay['id']} data={stay} />
        ))}
      </div>
      :
        <div className="grid grid-cols-1 gap-6 md:gap-4 sm:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4">
        {renderskeletonLoader()}
        {renderskeletonLoader()}
        {renderskeletonLoader()}
        </div>
      }
      <div className="flex mt-16 justify-center items-center">
      <Pagination count={totalpages} pageno={pageNumber?.toString()} 
        page_url="tiny-house-stories"
        />
      </div>
      
    </div>
  );
};

export default SectionGridFilterCard;
