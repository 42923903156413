import React, { FC, Fragment, useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import { Tab } from "@headlessui/react";
import SectionAibnbReviews from "components/SectionAibnbReviews/SectionAibnbReviews";
import SectionFeedbackForm from "components/SectionFeedbackForm/SectionFeedbackForm";


import { TaxonomyType } from "data/types";
const DEMO_CATS: TaxonomyType[] = [];

const { REACT_APP_SITE_KEY } = process.env;

export interface PageContactProps {
  className?: string;
  autoFocus?: boolean;
}
const PageContact: FC<PageContactProps> = ({
  className = "",
  autoFocus = false
}) => {

  const [details, setDetails] = useState({
    name: "",
    email: "",
    contactnumber: "",
    subject: "Keep in Touch with Tiny Away",
    message: "",
    google_captcha: ""
  });
  const [showPopover, setShowPopover] = useState(autoFocus);
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const NameRef = useRef<HTMLInputElement>(null);
  const EmailRef = useRef<HTMLInputElement>(null);
  const ContactNumberRef = useRef<HTMLInputElement>(null);
  const SubjectRef = useRef<HTMLInputElement>(null);
  const MessagesRef = useRef<HTMLInputElement>(null);
  const captchaRef = useRef<ReCAPTCHA>(null);
  const [errorMsg, setErrorMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');



  const section1Ref = useRef<HTMLDivElement | null>(null);
  const section2Ref = useRef<HTMLDivElement | null>(null);
  const section3Ref = useRef<HTMLDivElement | null>(null);

  // Function to scroll to a specific section
  const scrollToSection = (ref: React.RefObject<HTMLDivElement>) => {
    ref.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  const submitHandler = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const isInvalid = validateInput();
    if (!isInvalid) {
      //const token = captchaRef.current?.getValue();
      captchaRef.current?.reset();
      // console.log(token);
      //console.log(details.google_captcha);

      axios.post(process.env.REACT_APP_BASE_URL + '/customer_enquiries', details)
        .then(function (response) {
          //console.log(response.data.response);
          if (response.data.status == 200) {
            setSuccessMsg(response.data.response);
            setDetails({
              name: "",
              email: "",
              contactnumber: "",
              subject: "",
              message: "",
              google_captcha: ""
            });
          } else {
            setErrorMsg(response.data.response);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      setSuccessMsg('');
    }
  };
  useEffect(() => {
    //console.log({ showPopover, 'EmailRef': EmailRef.current, 'NameRef': NameRef.current });
    if (showPopover && NameRef.current && !details.name) {
      NameRef.current.focus();
    } else if (showPopover && EmailRef.current && !details.email) {
      EmailRef.current.focus();
    } else if (showPopover && ContactNumberRef.current && !details.contactnumber) {
      ContactNumberRef.current.focus();
    } else if (showPopover && SubjectRef.current && !details.subject) {
      SubjectRef.current.focus();
    } else if (showPopover && MessagesRef.current && !details.message) {
      MessagesRef.current.focus();
    }
  }, [showPopover]);
  const validateInput = () => {
    const fields = [
      {
        name: 'name',
        value: details.name,
        message: 'Your Name should not be blank.'
      },
      {
        name: 'email',
        value: details.email,
        message: 'Your Email should not be blank.'
      },
      {
        name: 'contactnumber',
        value: details.contactnumber,
        message: 'Contact Number should not be blank.'
      },
      {
        name: 'subject',
        value: details.subject,
        message: 'Subject should not be blank.'
      },
      {
        name: 'message',
        value: details.message,
        message: 'Message should not be blank.'
      },
      {
        id: 'google_captcha',
        value: details.google_captcha,
        message: 'Captcha should not be blank.'
      }
    ]
    const isNotFilled = fields.some(field => {
      if (field.value.trim() === '') {
        setErrorMsg(field.message);
        setShowPopover(true);
        return true;
      }
      setShowPopover(false);
      setErrorMsg('');
      return false;
    });
    return isNotFilled;
  };
  function SetRecaptchaVal(value: any) {
    setDetails({ ...details, google_captcha: value });
  }
  const renderContactUS = () => {
    return (
      <div className="about-page-content">
        <div className="flex-2">
          <div>
            <img src="https://pictures.tinyaway.com/filters:format(jpg)/730x650/contactus/contactus_banner.jpg" />
          </div>
          <div className="contaent-looking-hepl">
            <div>
              <h2>CONTACT US</h2>
              <p className="font-17">Looking for help? <br></br>Let us know—we’re here for you.</p>
              <div className="icon-flex">
                <div className="icon-set">
                  <a target="_blank" href="https://tinyaway.freshdesk.com/support/solutions"><span><i className="las la-comments"></i></span></a>
                  <p>The fastest and easiest way to get in touch with us about anything and everything.</p>
                </div>
                <div className="icon-set">
                  <span onClick={() => scrollToSection(section1Ref)}><i className="las la-comment-dots"></i></span>
                  <p>Our team will get back to you within 24 hours. </p><a href="">ask@tinyaway.com</a>
                </div>
              </div>


            </div>
          </div>
        </div>
        <div className="container relative space-y-10 mb-24 lg:space-y-10 lg:mb-32 mt-5">
          <h2 className="page-title mt-5 pt-4">INTERNATIONAL OFFICES</h2>
          <div className="grid gap-6 md:gap-8 sm:grid-cols-2 lg:grid-cols-1 xl:grid-cols-1 grid-gap-15">
            <Tab.Group >
              <Tab.List >
                <Tab>
                  <div>
                    <div className="card-contact card-color-1" >
                      <img src="https://pictures.tinyaway.com/filters:format(svg)/icons/singapore_office_icon.svg" />
                      {/* <h4>singapore OFFICES</h4> */}
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div>
                    <div className="card-contact card-color-1" >
                      <img src="https://pictures.tinyaway.com/filters:format(svg)/icons/australiya_office_icon.svg" />
                      {/* <h4>AUSTRALIA OFFICES</h4> */}
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div>
                    <div className="card-contact card-color-2">
                      <img src="https://pictures.tinyaway.com/filters:format(svg)/icons/taiwan_office_icon.svg" />
                      {/* <h4>AUSTRALIA OFFICES</h4> */}
                    </div>
                  </div>
                </Tab>
                <Tab>
                  <div>
                    <div className="card-contact card-color-3">
                      <img src="https://pictures.tinyaway.com/filters:format(svg)/icons/malaysia_office_icon.svg" />
                      {/* <h4>AUSTRALIA OFFICES</h4> */}
                    </div>
                  </div>
              </Tab>
              </Tab.List>
              <Tab.Panel>
                <div className="row-office-show-hide">
                  <div className="office-details">
                    <span>
                      <img src="https://pictures.tinyaway.com/filters:format(svg)/icons/contact_us_office_icon.svg" />
                    </span>
                    <h1>Singapore Headquarter</h1>
                    <p>38 Jalan Pemimpin, #08-01 M38, Singapore 577178</p>
                    <a href="">ask@bigtiny.com.sg</a><br></br>
                    <a href="">+65 6525 5282</a>
                  </div>
                  
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div className="row-office-show-hide">
                  <div className="office-details">
                    <span>
                      <img src="https://pictures.tinyaway.com/filters:format(svg)/icons/contact_us_office_icon.svg" />
                    </span>
                    <h1>Victoria Office</h1>
                    <p>22 Lara Way, Campbellfield, VIC 3061, Australia</p>
                    <a href="">ask@tinyaway.com</a>
                  </div>
                  <div className="office-details">
                    <span>
                      <img src="https://pictures.tinyaway.com/filters:format(svg)/icons/contact_us_office_icon.svg" />
                    </span>
                    <h1>New South Wales Office</h1>
                    <p>294 Palmyra Avenue, Shanes Park, NSW 2747, Australia</p>
                    <a href="">ask@tinyaway.com</a>
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div className="row-office-show-hide">
                  <div className="office-details">
                    <span>
                      <img src="https://pictures.tinyaway.com/filters:format(svg)/icons/contact_us_office_icon.svg" />
                    </span>
                    <h1>Taiwan Office</h1>
                    <p>110, Taipei City, Xinyi District, Keelung Rd, Section 1, No. 206, 13F</p>
                    <a href="">ask@bigtiny.com.sg</a>
                    {/* <a href="">+65 6525 5282</a> */}
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div className="row-office-show-hide">
                  <div className="office-details">
                    <span>
                      <img src="https://pictures.tinyaway.com/filters:format(svg)/icons/contact_us_office_icon.svg" />
                    </span>
                    <h1>Malaysia Office</h1>
                    <p>E-32-1, Menara Suezcap 2, KL Gateway, No. 2, Jalan Kerinchi, Gerbang Kerinchi Lestari, 59200 Kuala Lumpur</p>
                    <a href="">ask@bigtiny.com.sg</a>
                    <a href="">+65 6525 5282</a>
                  </div>
                  <div className="office-details">
                    <span>
                      <img src="https://pictures.tinyaway.com/filters:format(svg)/icons/contact_us_office_icon.svg" />
                    </span>
                    <h1>Malaysia Production Plant</h1>
                    <p>No. 36 Jalan I Park 1/3, Taman Perindustruan I Park, 81000, Kulai, Johor, Malaysia</p>
                    <a href="">ask@bigtiny.com.sg</a>
                    <a href="">+65 6525 5282</a>
                  </div>
                </div>
              </Tab.Panel>
            </Tab.Group>
          </div>
        </div>
        <div ref={section1Ref}>
          <SectionFeedbackForm />
        </div>
      </div>
    )
  }
  return (
    <div className="full " >
    <Helmet title="Tiny Away | Contact US"></Helmet>
      {renderContactUS()}
      <SectionSubscribe2 />
    </div>
  );
};
export default PageContact;
