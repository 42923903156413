import React, { FC, useEffect } from "react";
import Heading from "components/Heading/Heading";
import Glide from "@glidejs/glide";
import { PromoCodeDataTaxonomy } from "data/types";
import ncNanoId from "utils/ncNanoId";
import NextPrev from "shared/NextPrev/NextPrev";

export interface SectionPromoCodeSliderProps {
    className?: string;
    itemClassName?: string;
    categories?: PromoCodeDataTaxonomy[];
    itemPerRow?: 1 | 2 | 3;
    sliderStyle?: "style1" | "style2";
}
const DEMO_CATS: PromoCodeDataTaxonomy[] = [];
const SectionPromoCodeSlider: FC<SectionPromoCodeSliderProps> = ({
    className = "",
    itemClassName = "",
    categories = [],
    itemPerRow = 1,
    sliderStyle = "style1",
}) => {
    const UNIQUE_CLASS = "glide_" + ncNanoId();
    useEffect(() => {
        if (document.querySelector(`.${UNIQUE_CLASS}`)) {
            new Glide(`.${UNIQUE_CLASS}`, {
                perView: itemPerRow,
                gap: 32,
                bound: true,
                breakpoints: {
                    1280: {
                        perView: itemPerRow - 1,
                    },
                    1024: {
                        gap: 20,
                        perView: itemPerRow - 1,
                    },
                    768: {
                        gap: 20,
                        perView: itemPerRow - 2,
                    },
                    640: {
                        gap: 20,
                        perView: itemPerRow - 3,
                    },
                    500: {
                        gap: 20,
                        perView: 1.3,
                    },
                },
            }).mount();
        }
    }, [UNIQUE_CLASS, categories]);
    return (
        <div className={`nc-SectionPromoCodeSlider ${className}`}>
            <div className={`${UNIQUE_CLASS} flow-root`}>
                <div className="glide__track" data-glide-el="track">
                    <ul className="glide__slides">
                        {categories.map((item, index) => (
                            <li key={index} className={`glide__slide ${itemClassName}`}>
                                {/* {renderCard(item, index)} */}

                                <div className="white-color-bg">
                                    <div className="flex-warp-end-left">
                                        <h4>PROMO CODE - {item.couponCode}</h4>
                                        <div className="flex gap-2">

                                        </div>
                                    </div>
                                    <p className="mb-0-p">{item.heading}<br></br>{item.couponDesc}</p>
                                    <div className="left-right-boder-box"><div className="hr"></div></div>
                                    <div className="flex-wrop-textend-left">
                                        <div className="sm-text-lg">
                                            Expires {item.expiry_date}
                                        </div>
                                        <i className="text-lg las la-info-circle pt-3"></i>
                                    </div>
                                    <div className="color-sm-prom">Non-applicable Activity List</div>
                                </div>

                            </li>
                        ))}
                    </ul>
                </div>
                {categories.length > 2 && <NextPrev className="justify-center" />}
            </div>
        </div>
    );
};

export default SectionPromoCodeSlider;
