import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import { TaxonomyType } from "data/types";
import { Link } from "react-router-dom";
import convertNumbThousand from "utils/convertNumbThousand";

export interface CardCategory4Props {
  className?: string;
  taxonomy: TaxonomyType;
  listingType?: string;
}

const CardCategory4: FC<CardCategory4Props> = ({
  className = "",
  taxonomy,
  listingType
}) => {
  const { count, name, href = "/", thumbnail,categoryIcon } = taxonomy;
  return (
    <Link
      to="/tiny-house-stories"
      className={`nc-CardCategory4 flex flex-col ${className}`}
      data-nc-id="CardCategory4"
    >
      {/* {href} */}
      <div
        className={`flex-shrink-0 relative w-full aspect-w-6 aspect-h-6 sm:aspect-h-6 h-0  group`}
      >
        <span className="absolute  bottom-3rem flex h-14 items-center justify-center m-auto rounded rounded-[40px] top-60 w-14 z-50">
        <span>
          <img src={categoryIcon} className="w-10 h-10 object-contain" />
        </span>
        </span>
        <NcImage
          src={thumbnail}
          className="object-cover w-full h-full"
        />
        <span className="opacity-0 group-hover:opacity-100 absolute inset-0 bg-black bg-opacity-10 transition-opacity"></span>
      </div>
      <div className="mt-10 px-2 truncate text-center">
        <h2
          className={`Source-Sans-3 font-sm-mid-20 uppercase font-bold`}
        >
          {name}
        </h2>
        <span
          className={`block mt-0 text-sm text-neutral-6000 dark:text-neutral-400`}
        >
          {convertNumbThousand(count || 0)}
          {` `}
          {listingType}
          {(!listingType || listingType === "stay") && "things to do"}
          {listingType === "car" && "cars"}
          {listingType === "experiences" && "experiences"}
        </span>
      </div>
    </Link>
  );
};
export default CardCategory4;
