import Label from "components/Label/Label";
import React, { FC, Fragment, useState, useEffect, useRef } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'
export interface PageWriteReviewCodeProps {
  className?: string;
  DefaultUserID?: string;
}

const PageWriteReviewCode: FC<PageWriteReviewCodeProps> = ({
  className = "",
  DefaultUserID = localStorage.getItem("user_id") ? localStorage.getItem("user_id") : "",
}) => {
  const options = {
    title: {
      text: 'Total Gift Card Value USD$500',
      align: 'left'
    },
    series: [{
      type: 'pie',
      data: [{
        y: 1,
        name: "Used Gift Value",
        color: "#000000"
      }, {
        y: 7,
        name: "Balance Gift Value",
        color: "#f15e75"
      }]
    }]
  }


  const PagePeferralProgram = () => {
    return (
      <div className="right-user-details-card">
        <div className="card h-full">
          <div className="flex justify-between"><h4>Review Attractions You've Visited</h4></div>
          <p>Get rewarded for exploring and enjoy exclusive deals</p>


          <div className="w-full">
          </div>
        </div>




      </div>
    );
  };

  return (
    <div className="bgcolor-light pb-14 pt-10">
      <div className="container">

        <div className="row-profile  mt-5 mb-5">

          <CommonLayout />
          {PagePeferralProgram()}

        </div>
      </div>
    </div>
  );
};
export default PageWriteReviewCode;
