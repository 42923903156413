import Label from "components/Label/Label";
import React, { FC, Fragment, useState, useEffect, useRef } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import CommonLayout from "./CommonLayout";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official'
export interface PageMyPostProps {
  className?: string;
  DefaultUserID?: string;
}

const PageMyPost: FC<PageMyPostProps> = ({
  className = "",
  DefaultUserID = localStorage.getItem("user_id") ? localStorage.getItem("user_id") : "",
}) => {
  const options = {
    title: {
      text: 'Total Gift Card Value USD$500',
      align: 'left'
    },
    series: [{
      type: 'pie',
      data: [{
        y: 1,
        name: "Used Gift Value",
        color: "#000000"
      }, {
        y: 7,
        name: "Balance Gift Value",
        color: "#f15e75"
      }]
    }]
  }


  const PagePeferralProgram = () => {
    return (
      <div className="right-user-details-card">
        <div className="card">
          <div className="flex justify-between"><h4>My Post</h4></div>

          <div className="flex">
            <div className="card-earn card-earn1">
              <h5>POST A STORY / WRITE A REVIEW TO EARN TINYAWAY'S POINTS</h5>
              <div>
               <img src="https://pictures.tinyaway.com/filters:format(svg)/icons/TA_UIUX_MyPost.svg" />
               
                <div className="row-card card-text">
              <div className="col-4"> 
                <div className="text-card-icon text">2</div>
                <div className="text-card-icon">MOMENT</div>
              </div>
                
              <div className="col-4"> 
                <div className="text-card-icon text">2</div>
                <div className="text-card-icon">MOMENT</div>
              </div>

              <div className="col-4"> 
                <div className="text-card-icon text">3 (235 POINTS)</div>
                <div className="text-card-icon">MOMENT</div>
              </div>

            </div>

                <div className="text-center mt-5">
                  <a href="/pagecreatestory" className="btn btn-box-css big-btn"><i className="las la-edit"></i>  INVITE FRIENDS</a>
                  <a href="/PageWriteReview" className="btn btn-black big-btn m-8"><i className="las la-edit"></i>  WRITE REVIEW</a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-5">
        <h4>Your Post</h4>
    <div className="list-tab-account">
    <a href="" className="active">Stories</a>
    <a href="">Reviews</a>
    </div>
    <div className="line-mid mt-5 mb-5"></div>
    <div className="flex">
      <div className="w-70">
        <div className="content content1">
          <h4>A WEEKEND AT HUNTINGDON</h4>
          <p>With a fresh sense of adventure, we hit the road bound for a weekend away in Noosa. Nestled amidst Noosa’s northern lakelands, we arrived at our Tiny Home. The home was easy to spot from the road and the host's directions made it simple to find and enter. Situated on a spacious plot of land, we quickly found ourselves surrounded by wildlife. We unfurled the sunshade only to be greeted by a native frog and a small bat. Later while taking in the sunset with a glass of wine in hand, we spotted a kingfisher, possums, lizards, and numerous local birds. Our time at the property allowed us to take a break from the hustle and bustle of life and appreciate the simplicity of our surroundings. Special mentions include the delightful scent of the bathroom products, the fresh and sweet-tasting tap water, the perfectly aligned angle of the tiny home for sunset views, and the enjoyable short walk to the nearby lake.</p>
         
        </div>
        
        
        <div  className="content  mt-10">
          <div className="flex gap-20 table-row-2">
            <div className="w-100px"><b>BLOCATION </b> </div><div><b>POSTED DATE</b> </div>
          </div>
          <div className="flex gap-20 table-row-2">
            <div  className="w-100px">ROSES GAP VIEW </div><div>SUN, 04 MAY 24 </div>
          </div>
          <div className="flex gap-20 table-row-2">
            <div  className="w-100px">TOTAL AMOUNT </div><div>1415 </div>
          </div>
          <div className="flex gap-20 table-row-2">
            <div  className="w-100px"><a href="" className="bold-name color-light-blu">View Post / Story</a></div>
          </div>
        </div>
      </div>
      <div className="w-30">
          <div className="right-box-add-on"> 
              <img src="https://pictures.tinyaway.com/filters:format(jpg)/200x150/Listings/Images/2024/09/952/ksegndmyjunkljeey8hp1725612069256.jpg" />
              <a href="" className="btn btn-box-css">Share booking</a>
              <a href="" className="btn btn-box-theme">Request to canel</a>
          </div>
      </div>
    </div>

        </div>

        <div className="text-center  mt-5">
                  <a href="/pagecreatestory" className="btn btn-box-css big-btn"><i className="las la-edit"></i> INVITE FRIENDS</a>
                  <a href="/PageWriteReview" className="btn btn-black big-btn m-8"><i className="las la-edit"></i>  WRITE REVIEW</a>
                </div>
 


      </div>
    );
  };

  return (
    <div className="bgcolor-light pb-14 pt-10">
      <div className="container">

        <div className="row-profile  mt-5 mb-5">

          <CommonLayout />
          {PagePeferralProgram()}

        </div>
      </div>
    </div>
  );
};
export default PageMyPost;
