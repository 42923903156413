import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { WishListDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link, useHistory } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import PetFriendly from "components/PetFriendly/petFriendly";
import Badge from "shared/Badge/Badge";
import { useWishlist } from "WishlistContext/WishlistContext";
import { useAuth } from "hooks/useAuth"; // Adjust path if needed
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface StayCardHProps {
  className?: string;
  data?: WishListDataType;
  size?: "default" | "small";
}

const DEMO_DATA = DEMO_STAY_LISTINGS[0];
const StayCardH: FC<StayCardHProps> = ({
  className = "",
  size = "default",
  data = DEMO_DATA,
}) => {
  const { user } = useAuth();
    const { wishlist, toggleWishlist } = useWishlist();
    const history = useHistory();
    const userId = user?.user_id; // Fetch user ID from localStorage
    const isWishlisted = wishlist.includes(data.id);
  
    const handleWishlistClick = (e: React.MouseEvent) => {
      e.preventDefault(); // Prevent navigating to listing when clicking the heart
      toggleWishlist(data.id); // ✅ Calls add/remove wishlist function
    };
  const {
    galleryImgs,
    address,
    title,
    href,
    pets_frendly,
    price,
    id,
    currency_code,
    saleOff,
    isAds
  } = data;

  const renderSliderGallery = () => {
    return (
      <div className="relative flex-shrink-0 w-full sm:w-100 ">
        <GallerySlider
          ratioClass="aspect-w-6 aspect-h-5"
          galleryImgs={galleryImgs}
          uniqueID={`stay-${id}`}
        />
        <div className="absolute flex justify-between justify-content-between left-2 pr-3 top-2 w-100 w-full">
          <div className="flex gap-2">
            {saleOff && <SaleOffBadge desc={saleOff} />}
            {pets_frendly && <PetFriendly desc={pets_frendly} />}
          </div>
          <div>
          <button onClick={handleWishlistClick} className="focus:outline-none">
          <BtnLikeIcon isLiked={isWishlisted}/>
          </button>
          </div>
        </div>
      </div>
    );
  };


  const renderContent = () => {
    return (
      <div className={size === "default" ? "p-0 space-y-0" : "p-3 space-y-2"}>
        <div className="flex p-4">
          <div className="space-y-0 w-5/6">

            <div className="flex items-center space-x-2">
              {isAds && <Badge name="ADS" color="green" />}
              <h2
                className={` font-medium capitalize ${size === "default" ? "text-lg" : "text-base"
                  }`}
              >
                <span className="line-clamp-1">{title}</span>
              </h2>
            </div>
            {/* <span className="text-sm text-neutral-500 dark:text-neutral-400">
           
           Tiny House . {bedrooms} bedroom
          </span> */}
            <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-sm space-x-2">
              <span className="">{address}</span>
            </div>
           
          </div>

          <div className="flex justify-between items-top">
            <span className="text-lg font-semibold text-right">
              {currency_code}{price}
              {` `}
              {size === "default" && (
                <span className="text-sm text-neutral-500 dark:text-neutral-400 font-normal">
                  /night
                </span>
              )}
            </span>

          </div>
        </div>
        <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
        <div className="flex  p-4">
        <a className="flex justify-end" href={href} rel="noopener noreferrer">
          <ButtonPrimary>BOOK YOUR STAY</ButtonPrimary>
        </a>
        </div>
      </div>

    );
  };

  return (
    <div
      className={`hover-bg-color nc-StayCard group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-2xl overflow-hidden hover:shadow-xl transition-shadow ${className}`}
      data-nc-id="StayCard"
    >
      <Link to={href}>
        {renderSliderGallery()}
        {renderContent()}
      </Link>
    </div>
  );
};

export default StayCardH;
